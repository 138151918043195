import React from "react";
import { useHistory } from "react-router-dom"
import { IonIcon, IonLabel, IonThumbnail, IonImg, IonSkeletonText } from "@ionic/react";
import { timeOutline, flameOutline, bulbOutline, lockClosed, leaf } from "ionicons/icons"
import { Link } from "react-router-dom"
import { useNutritionInfo, useRecipeMedia, useDifficultyClassname } from "../../hooks"
import styles from "./RecipeItem.module.css"

const LinkWrapper = ({ disabled, href, children, from }) => (
    disabled ? <>{children}</> : <Link to={{ 'pathname': href, 'state': { 'from': from }}}>{children}</Link>
)

const RecipeItem = (props) => {
    const { id, thumb, meal, title, preptime, difficulty, tags, isLocked, isDisabled, footerCta } = props;
    const calories = useNutritionInfo(props.rp_calories);
    const difficultyClassname = useDifficultyClassname(props.difficulty);
    const history = useHistory();

    const { media, isLoading, isError } = useRecipeMedia(!thumb ? id : null);

    // used to hide the eaten button when coming from the Vegly plans section
    var referrer = history.location['state'] && history.location['state']['referrer'];

    var detailUrl = props.fromPlan ? `/recipes/${id}/plan` : `/recipes/${id}`;

    const isVegan = tags && tags.indexOf('vegan') >= 0;

    return(
        <div className={styles.card}>
            <LinkWrapper disabled={isLocked || isDisabled} href={detailUrl} from={referrer}>
                <IonThumbnail className={styles.thumb} slot="start">
                    { isLocked && 
                        <span className={styles.lockOverlay}>
                            <IonIcon icon={lockClosed}/>
                        </span>
                    }
                    {!thumb ?
                        media ? <IonImg src={`${process.env.REACT_APP_API_HOST}/${media[1].source_url}`}/> : <IonSkeletonText /> :
                        <IonImg src={`${process.env.REACT_APP_API_HOST}/${thumb}`}/>
                    }
                </IonThumbnail>
            </LinkWrapper>
            <IonLabel className={styles.vyIonLabel}>
                <LinkWrapper disabled={isLocked || isDisabled} href={detailUrl} from={referrer}>
                    <div className={styles.subhead}>
                        <h6 className={styles.labelInfo}>{meal}</h6>
                        <div className={styles.labelInfo}>
                            { isVegan &&
                                <span className={[styles.iconText, styles.veganBadge].join(' ')}><IonIcon icon={leaf}/>&nbsp;</span>
                            }
                            { preptime > 0 &&
                                <span className={styles.iconText}><IonIcon icon={timeOutline}/>&nbsp;{preptime}</span>
                            }
                            { calories > 0 &&
                                <span className={styles.iconText}><IonIcon icon={flameOutline}/>&nbsp;{calories}</span>
                            }
                        </div>
                    </div>
                    <h5 className={styles.title}>{title}</h5>
                </LinkWrapper>
                <footer className={styles.footer}>
                    <span>{ footerCta }</span>
                    { difficulty &&
                        <span className={[styles.labelInfo, styles[difficultyClassname]].join(' ')}>{difficulty}</span>
                    }
                </footer>
            </IonLabel>
        </div>
    );
}

export default RecipeItem;