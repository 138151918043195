import React, { useContext, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { IonPage, IonHeader, IonToolbar, IonSearchbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonGrid } from "@ionic/react"
import BackdropContent from "../../components/BackdropContent"
import VYBackButton from "../../components/VYBackButton"
import RecipeItem from "../../components/RecipeItem"
import FetchClient from "../../utils/FetchClient"
import { search, add } from "ionicons/icons"
import styles from "./PageRecipeSearch.module.css"

import { AppContext } from "../../AppContext"

const Q_FRAGMENT = `
	id
	title
	menus {
		id
		assocId
		title
		thumb
		meal
        preptime
        difficulty
		rp_calories
		rp_fat
		rp_saturates
		rp_protein
		rp_carbohydrates
		rp_sugars
		rp_fibre
        rp_salt
        tags
	}
    nutrition {
		calories
		fat
		saturates
		protein
		carbohydrates
		sugars
		fibre
		salt
		group_id
	}
	updated_at
`;

const GL_INITIAL_QUERY = `
    query($page: Int, $search_key: String, $search_fields: String, $search_tags: String) {
        listRecipes(page: $page, search_key: $search_key, search_fields: $search_fields, search_tags: $search_tags) {
            pagination {
                perPage
                current
                total
                last
            }
            results {
                id
                title
                preptime
                difficulty
                rp_calories
                is_pro
                tags
            }
        }
    }
`;

const Q_SWAP = `
	mutation($plan: Int!, $associd: Int, $recipe: Int) {
		UserEditPlan(plan: $plan, action: "swap", associd: $associd, recipe: $recipe) { ${Q_FRAGMENT} }
	}
`;

const Q_ADD = `
	mutation($plan: Int!, $recipe: Int, $meal: String, $groupid: Int) {
		UserEditPlan(plan: $plan, action: "add", recipe: $recipe, meal: $meal, groupid: $groupid) { ${Q_FRAGMENT} }
	}
`;

function useQuery() {
    let search = useLocation().search.substring(1);
    try {
        return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    } catch(err) {
        return null;
    }
}

function PageRecipeSearch(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [recipes, setRecipes] = useState(null);
    const [searchExpanded, toggleSearch] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [pageCursor, setPageCursor] = useState(1);
    const [hasLoadMore, setHasLoadMore] = useState(false);

    const { state, dispatch } = useContext(AppContext);

    const history = useHistory();
    const query = useQuery();

	useEffect(() => {
		window.analytics.page('Search recipes');
	}, [props.match])

    useEffect(() => {
        setIsLoading(true);
        
        const qlVars = {
            'page': pageCursor
        };
        if(query.m) {
            // filter by meal type
            qlVars['search_tags'] = query.m;
        }
        if(searchKeyword && searchKeyword.length > 2) {
            qlVars['search_key'] = searchKeyword;
            qlVars['search_fields'] = "title,method";
            // remove tags if the user is using free text search
            qlVars['search_tags'] = ""
        }
        setIsLoading(true);

		FetchClient({
			method: 'post',
			data: {
				query: GL_INITIAL_QUERY,
				variables: qlVars
			}
        })
        .then((res) => {
            const { results, pagination } = res.data.listRecipes;
            setHasLoadMore((pagination.total === 0 || pagination.current === pagination.last) ? false : true);
            setRecipes(pageCursor > 1 ? recipes.concat(results) : results);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
        
        window.analytics.track('Search recipes', { 'hasKeyword': (searchKeyword && searchKeyword.length > 2) || false, 'keyword': searchKeyword, 'page': pageCursor });

    }, [ pageCursor, searchKeyword ]);

	/**
	 * 
	 * @param {*} newMealId 
	 */
	const _onSwap = async (newRecipe) => {
		const { plan, swap, d, m } = query;
        var queryData;

		if(swap && swap !== 'null') {
			queryData = {
				query: Q_SWAP,
				variables: {
					'plan': parseInt(plan),
					'associd': parseInt(swap),
					'recipe': newRecipe
				}
			}
		} else {
			queryData = {
				query: Q_ADD,
				variables: {
					'plan': parseInt(plan),
					'recipe': newRecipe,
					'meal': m,
					'groupid': parseInt(d)
					
				}
			}
		}
		FetchClient({
			method: 'post',
			data: queryData
		})
		.then((res) => {
            dispatch({
                type: 'setEditedPlan',
                editedPlan: JSON.stringify(res.data.UserEditPlan)
            });
            history.goBack();
		})
		.catch((err) => {
			console.error(err);
		});
        window.analytics.track('Plan edit', { 'plan': plan, 'action': swap ? 'swap' : 'add' });
	}

    const _onChange = (e) => {
        let { value } = e.target;
        setSearchKeyword(value);
        setPageCursor(1);
    }

    const _onOpenSearch = () => {
        toggleSearch(!searchExpanded);
    }

    const _loadMore = () => {
        setPageCursor(pageCursor + 1);
    }

    // wait for the state to be loaded
    if(!state.user) return <></>;

    return(
        <IonPage>
            <IonHeader className={styles.pageHeader}>
                <IonToolbar className={styles.pageToolbar}>
                    <IonButtons slot="start">
                        <VYBackButton/>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={_onOpenSearch}>
                            <IonIcon slot="icon-only" icon={search} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle className={styles.title}>Search recipes</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <BackdropContent
                    title={
                        <IonGrid fixed>Choose a recipe</IonGrid>
                    }
                    //description={pendingSwap ? `Replace "${pendingSwap.title}" in your current plan`: ""}
                    backLayerContent={(
                        <div className={styles.searchbar}>
                            <IonSearchbar placeholder="Search by name or ingredient" onIonChange={_onChange}></IonSearchbar>
                        </div>
                    )}
                    frontLayerContent={(
                        <IonGrid fixed>
                            {(recipes && recipes.length < 1 && !isLoading) && 
                                <p className={styles.emptyPlaceholder}>No results found for {searchKeyword}. Try searching for ingredients or recipes names</p>
                            }
                            {recipes && recipes.map((recipe) =>
                                <RecipeItem key={`search-panel-${recipe.id}`} {...recipe} thumbid={recipe.id} meal={query && query.m} isLocked={!state.user.isPremium && recipe.is_pro} footerCta={
                                    <IonButton fill="clear" onClick={(e) => _onSwap(recipe.id)} disabled={!state.user.isPremium && recipe.is_pro}>
                                        <IonIcon slot="start" icon={add}/>
                                        Add to the plan
                                    </IonButton>
                                }/>
                            )}
                            {(!isLoading && hasLoadMore) &&
                                <div className={styles.footerActions}>
                                    <IonButton fill="clear" onClick={_loadMore}>Load more</IonButton>
                                </div>
                            }
                        </IonGrid>
                    )}
                    isExpanded={searchExpanded}
                    expandedSize="80px"
                />
            </IonContent>
        </IonPage>
    )
}

export default PageRecipeSearch