import React, { useState } from "react"
import { IonIcon, IonItem, IonLabel, IonButton } from "@ionic/react"
import { checkmarkOutline, checkmarkDoneOutline, listOutline, removeOutline } from "ionicons/icons"
import styles from "./GroceryItem.module.css"

const GroceryListItem = ({ id, rendered_name, name, qty, unit, recipes, onClick, isSelected = false, onShowRecipes = false }) => {
    const [showRecipes, setShowRecipes] = useState(false);

    const _onClick = (e) => {
        if(e.target.type == "button") {
            setShowRecipes(!showRecipes);
            if(onShowRecipes) onShowRecipes();
        } else {
            onClick(id);
        }
    }

    return(
        <>
            <IonItem lines="inset" className={[styles.groceryItem, isSelected ? styles.selected : ''].join(' ')} button onClick={_onClick}>
                <IonIcon slot="start" icon={isSelected ? checkmarkDoneOutline : removeOutline} className={styles.markIcon} />
                <IonLabel>
                    { rendered_name || name }, { unit !== 'DO_NOT_SHOW' && <span className={styles.quantity}>{qty/10000}&nbsp;{unit}</span> }
                    <div className={[styles.recipesList, showRecipes ? styles.expanded : ''].join(' ')}>
                        {recipes.map(recipe => <p>{recipe}</p>)}
                    </div>
                </IonLabel>
                <IonButton fill="clear"><IonIcon icon={listOutline}/></IonButton>
            </IonItem>
        </>
    );
}

export default GroceryListItem;