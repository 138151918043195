import React, { useContext, useEffect, useRef, useState } from "react"
import { getPlatforms, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonSegment, IonSegmentButton, IonLabel, IonFab, IonFabButton, IonIcon, IonImg, IonDatetime, IonList, IonItem, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption } from "@ionic/react"
import { useParams } from "react-router-dom"
import FetchClient from "../../utils/FetchClient"
import VYBackButton from "../../components/VYBackButton"
import NutritionSheet from "../../components/NutritionSheet"
import { restaurant, flameOutline, timeOutline, leaf } from "ionicons/icons"
import styles from "./PageRecipeDetail.module.css"
import moment from "moment"

import { AppContext } from "../../AppContext"

var nutritionBase = process.env.REACT_APP_NUTRITION_BASE;

const FRAGMENT_QFIELDS = `
    id
    title
    excerpt
    method
    preptime
    rp_calories
    rp_fat
    rp_saturates
    rp_protein
    rp_carbohydrates
    rp_sugars
    rp_fibre
    rp_salt
    tags
    ingredients {
        name
        rendered_name
        recipe_qty
        unit
    }
`;

const Q_FIND_PLAN_RECIPE = `
    query($id: Int, $day: String) {
        recipeDetail: PlanFindRecipe(id: $id) {
            ${FRAGMENT_QFIELDS}
            assocId
            groupid
        },
        UserCheckLog(plan_recipe: $id, day: $day)
    }
`;

const Q_FIND_RECIPE = `
    query($id: Int) {
        recipeDetail: findRecipe(id: $id) {
            ${FRAGMENT_QFIELDS}
        }
    }
`;

const Q_MEDIA = `
    query($id: Int) {
        RecipeFindMedia(id: $id) {
            size
            source_url
        }
    }
`;

const Q_LOG_MEAL = `
    mutation($id: Int, $datetime: String) {
        UserLogDiary(plan_recipe: $id, created_at: $datetime)
    }
`;

const isDesktop = getPlatforms().indexOf("desktop") > -1;

const servingAlertOptions = {
	header: "Adjust servings"
}

function PageRecipeDetail(props) {
    const { id, isFromPlan } = useParams();

    const [recipe, setRecipe] = useState(false);
	const [servings, setServings] = useState(1);
    const [media, setMedia] = useState(false);
    const [isLogged, setIsLogged] = useState(true);

    const { state, dispatch } = useContext(AppContext);
    
    const datepickerRef = useRef(null);
    const tabsContentRef = useRef(null);

	const pageSegments = {
		INGREDIENTS: "_segment-ingredients",
		METHOD: "_segment-method",
		NUTRIENTS: "_segment-nutrients"
	}
    const [activeSegment, setSegment] = useState(pageSegments.METHOD);
    const [stickySegment, setStickySegment] = useState(/*isDesktop*/true);

    var hideEatenBtn = false;
    if(props.location['state'] && props.location['state']['from']) {
        if(props.location['state']['from'] === 'plansStore')
            hideEatenBtn = true;
    }
    if(!isFromPlan) {
        hideEatenBtn = true;
    }

    const isVegan = recipe && recipe.tags && recipe.tags.indexOf('vegan') >= 0;

	useEffect(() => {
		window.analytics.page('Recipe', activeSegment, { 'recipe': id });
	}, [props.match, activeSegment]);

    useEffect(() => {
        if(!id)
            return;

		FetchClient({
			method: 'post',
			data: {
				query: isFromPlan ? Q_FIND_PLAN_RECIPE : Q_FIND_RECIPE,
				variables: {
                    id: parseInt(id),
                    day: moment().format('YYYY-MM-DD')
                }
			}
		}).then((res) => {
            setRecipe(res.data.recipeDetail);
            setIsLogged(res.data.UserCheckLog)
		}).catch((err) => {
			console.log(err);
		});
    }, [id])

    useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: Q_MEDIA,
				variables: {
                    id: parseInt(recipe.id)
                }
			}
		}).then((res) => {
            setMedia(res.data.RecipeFindMedia);
		}).catch((err) => {
			console.log(err);
		});
    }, [recipe])

    const _renderExcerptMarkup = () => {
        if(!recipe)
            return;
        return {__html: recipe.excerpt};
    }

    const _renderMethodMarkup = () => {
        if(!recipe)
            return;
        return {__html: recipe.method};
    }

    const _promptLogMeal = () => {
		// for us Sunday is 6 instead of 0
		var weekdayIndex = moment().day() - 1 > 0 ? moment().day() - 1 : 6;
        var recipeDayIndex = recipe.groupid;

        if(weekdayIndex !== recipeDayIndex) {
            datepickerRef.current.open();
            setTimeout(() => {
                // workaround to add a title to the date picker
                var p = document.createElement('p');
                p.className = styles.datepickerTitle;
                p.textContent = "When did you eat this?";
                document.querySelector('.picker-toolbar').appendChild(p);
            }, 200)
            
        } else {
            _saveLogMeal(moment().format('YYYY-MM-DD HH:mm:ss'));
        }
    }

    const _saveLogMeal = (date) => {
		FetchClient({
			method: 'post',
			data: {
				query: Q_LOG_MEAL,
				variables: {
                    id: recipe.assocId,
                    datetime: moment(date).format('YYYY-MM-DD HH:mm:ss')
                }
			}
        })
        .then((res) => {
            setIsLogged(true);
            dispatch({
                type: 'setDayCalories',
                dayCaloriesLog: res.data.UserLogDiary
            });
		    window.analytics.track('Recipe eaten', { 'surface': 'recipe_detail' });
        })
        .catch((err) => {
			console.log(err);
        });
    }

	/**
	 * 
	 * @param {*} segment 
	 */
	const _toggleSegment = (e) => {
		setSegment(e.detail.value);
    }

    /**
     * 
     */
    /*
    const _onScroll = () => {
        const headerSize = document.getElementsByTagName("ion-header")[0].offsetHeight;
        const segmentCoords = tabsContentRef.current.getBoundingClientRect();
        if(!stickySegment && segmentCoords.y + 1 < headerSize) {
            setStickySegment(true);
        } else if(stickySegment && segmentCoords.y + 1 > headerSize) {
            setStickySegment(false);
        }
    }
    */

	const _onChangeServings = (value) => {
		setServings(value);
		window.analytics.track('Recipe actions', { 'action': 'servings', 'servings': value });
	}

    return(
		<IonPage style={{backgroundColor: "#FFFFFF"}}>
			<IonHeader>
				<IonToolbar>
                    <IonButtons slot="start">
                        <VYBackButton/>
                    </IonButtons>
                    <IonTitle>Recipe</IonTitle>
				</IonToolbar>
                { stickySegment && 
                    <IonSegment mode="md" value={activeSegment} onIonChange={_toggleSegment}>
                        <IonSegmentButton value={pageSegments.INGREDIENTS}>
                            <IonLabel>ingredients</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value={pageSegments.METHOD}>
                            <IonLabel>Method</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value={pageSegments.NUTRIENTS}>
                            <IonLabel>Nutrients</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                }
			</IonHeader>
			<IonContent scrollEvents={true}> {/*onIonScrollEnd={isDesktop ? false : _onScroll}>*/}
                <IonGrid fixed>
                    <IonRow>
                        <IonCol sizeMd="4" sizeXs="12">
                            <div className={styles.pageCover}>
                                <div className={styles.coverContainer}>
                                    {(media && media[3]) &&
                                        <IonImg src={`${process.env.REACT_APP_API_HOST}/${media[3].source_url}`} />
                                    }
                                </div>
                                <div className={styles.pageTitleWrapper}>
                                    <div className={styles.pageTitle}>
                                        <h1>{recipe.title}</h1>
                                        <div className={styles.subline}>
                                            { isVegan &&
                                                <span className={[styles.iconText, styles.veganBadge].join(' ')}><IonIcon icon={leaf}/>&nbsp;</span>
                                            }
                                            {recipe.preptime > 0 &&
                                                <span className={styles.iconText}><IonIcon icon={timeOutline}/>&nbsp;{recipe.preptime}</span>
                                            }
                                            {recipe.rp_calories > 0 &&
                                                <span className={styles.iconText}><IonIcon icon={flameOutline}/>&nbsp;{(recipe.rp_calories/nutritionBase).toFixed()}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol>
                            {/*
                            <IonSegment mode="md" className={stickySegment ? styles.hidden : ''} value={activeSegment} onIonChange={_toggleSegment}>
                                <IonSegmentButton value={pageSegments.INGREDIENTS}>
                                    <IonLabel>ingredients</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value={pageSegments.METHOD}>
                                    <IonLabel>Method</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value={pageSegments.NUTRIENTS}>
                                    <IonLabel>Nutrients</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                            */}
                            {recipe &&
                                <div ref={tabsContentRef} className={styles.tabWrapper}>
                                    {activeSegment === pageSegments.INGREDIENTS &&
                                    <div className={styles.tabIngredients}>
                                        <div className={styles.toolbar}>
                                            <span className={styles.servingsSelector}>
                                                <IonLabel>Servings:</IonLabel>
                                                <IonSelect 
                                                    interfaceOptions={servingAlertOptions}
                                                    value={servings} 
                                                    placeholder={`Change servings`}
                                                    onIonChange={e => _onChangeServings(e.detail.value)}
                                                >	
                                                    <IonSelectOption value={1}>1 serving</IonSelectOption>
                                                    <IonSelectOption value={2}>2 servings</IonSelectOption>
                                                    <IonSelectOption value={3}>3 servings</IonSelectOption>
                                                    <IonSelectOption value={4}>4 servings</IonSelectOption>
                                                    <IonSelectOption value={5}>5 servings</IonSelectOption>
                                                    <IonSelectOption value={6}>6 servings</IonSelectOption>
                                                    <IonSelectOption value={7}>7 servings</IonSelectOption>
                                                    <IonSelectOption value={8}>8 servings</IonSelectOption>
                                                    <IonSelectOption value={9}>9 servings</IonSelectOption>
                                                    <IonSelectOption value={10}>10 servings</IonSelectOption>
                                                </IonSelect>
                                            </span>
                                            {/*<IonButton fill="clear" onClick={_clearGroceryList}>Clear list</IonButton>*/}
                                        </div>
                                        <IonList>
                                            {recipe.ingredients.map((ingredient, index) => (
                                                <IonItem key={index}>
                                                    <IonLabel>
                                                        <span>{ingredient.rendered_name || ingredient.name},&nbsp;</span>
                                                        { ingredient.unit !== 'DO_NOT_SHOW' &&
                                                            <span className={styles.quantity}>{`${(ingredient.recipe_qty/10000) * servings} ${ingredient.unit}`}</span>
                                                        }
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </IonList>
                                    </div>
                                    }
                                    {activeSegment === pageSegments.METHOD &&
                                    <div>
                                        <div dangerouslySetInnerHTML={_renderExcerptMarkup()}/>
                                        <div dangerouslySetInnerHTML={_renderMethodMarkup()}/>
                                    </div>
                                    }
                                    {activeSegment === pageSegments.NUTRIENTS &&
                                    <div>
                                        <NutritionSheet nutrition={{
                                            'calories': recipe.rp_calories,
                                            'fat': recipe.rp_fat,
                                            'saturates': recipe.rp_saturates,
                                            'protein': recipe.rp_protein,
                                            'carbohydrates': recipe.rp_carbohydrates,
                                            'sugars': recipe.rp_sugars,
                                            'fibre': recipe.rp_fibre,
                                            'salt': recipe.rp_salt
                                        }}/>
                                    </div>
                                    }
                                </div>
                            }
                            { isFromPlan &&
                                <div className={styles.fabLabel}>
                                    <p>Did you eat this? Tap the knife and fork button to log this as eaten.</p>
                                </div>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
			</IonContent>
            {(!isLogged && !hideEatenBtn) &&
                <>
                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                        <IonFabButton onClick={_promptLogMeal}>
                            <IonIcon icon={restaurant} />
                        </IonFabButton>
                    </IonFab>
                    <IonDatetime 
                        ref={datepickerRef} 
                        style={{display: "none"}}
                        min={moment().startOf('isoweek').format('YYYY-MM-DD')}
                        max={moment().format("YYYY-MM-DD")} 
                        onIonChange={(e) => _saveLogMeal(e.detail.value)}
                    ></IonDatetime>
                </>
            }
		</IonPage>
    )
}

export default PageRecipeDetail