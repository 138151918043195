import React, { useContext, useEffect } from "react"
import { Link } from "react-router-dom";
import VYPageWrapper from "../VYPageWrapper"
import { IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonGrid, IonCol, IonRow } from "@ionic/react"
import FoodDiaryFab from "../../components/FoodDiaryFab"
import MembershipBanner from "../../components/MembershipBanner"
import styles from "./PageHome.module.css"
import { arrowForward } from "ionicons/icons";
import { useRecipeMedia, useUserCurrentPlan } from "../../hooks";
import WidgetCalories from "./WidgetCalories"
import FetchClient from "../../utils/FetchClient"
import moment from "moment"

import { AppContext } from "../../AppContext"
import GroceryUtil from "../../utils/GroceryUtil"

const NUTRITION_BASE = process.env.REACT_APP_NUTRITION_BASE;

const Q_GET_CALORIES = `
    query($day: String) {
        UserGetCaloriesDay(day: $day)
    }
`;

function WidgetNextMeal({ meal, onLog }) {
	const cover = useRecipeMedia(meal.id)
	const hasCover = !cover.isLoading && cover.media && cover.media[3];

	return(
		<IonCard mode="md" className={styles.widgetMeal}>
			{hasCover &&
				<Link to={`/recipes/${meal.assocId}/plan`}> 
					<div className={styles.coverImg} style={{backgroundImage: `url(${process.env.REACT_APP_API_HOST}/${cover.media[3].source_url})`}}></div>
				</Link>
			}
			<IonCardHeader>
				<IonCardSubtitle className={styles.widgetSubtitle}>Next meal</IonCardSubtitle>
				{meal &&
					<>
						<IonCardTitle><Link to={`/recipes/${meal.assocId}/plan`}>{meal.title}</Link></IonCardTitle> 
						<Link className={styles.button} to={"/tabs/plans"}>
            				<span>View plan</span>
							<IonIcon className={styles.icon} icon={arrowForward} />
						</Link>
					</>
				}
			</IonCardHeader>
			<FoodDiaryFab recipeId={meal.assocId} className={styles.fab} onClick={onLog}/>
		</IonCard>
	)
}

function WidgetGrocery({ grocery }) {
	GroceryUtil.list = grocery;
	var groceryList = GroceryUtil.flatten();

	return(
		<IonCard mode="md" className={styles.widgetGrocery}>
			<IonCardHeader>
				<IonCardSubtitle className={styles.widgetSubtitle}>Your grocery list</IonCardSubtitle>
				<IonCardTitle>Weekly shop</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				{groceryList &&
					groceryList.length > 0 ? 
						<>
							<p>{`You have ${groceryList.length} recipes in your grocery list`}</p>
							<Link className={styles.button} to={"/tabs/grocery"}>
								<span>View grocery list</span>
								<IonIcon className={styles.icon} icon={arrowForward} />
							</Link>
						</>
						:
						<>
							<p>Head over to your current plan and add something to your list</p> 
							<Link className={styles.button} to={"/tabs/plans"}>
								<span>Go to your plan</span>
								<IonIcon className={styles.icon} icon={arrowForward} />
							</Link>
						</>
				}
			</IonCardContent>
		</IonCard>
	)
}

function Home(props) {
	const userCurrentPlan = useUserCurrentPlan();
	const [nextMeal, setNextMeal] = React.useState(null);
	const { state, dispatch } = useContext(AppContext);

	useEffect(() => {
		window.analytics.page('Home');
	}, [props.match])

	React.useEffect(() => {
		const plan = userCurrentPlan.data;
		if(!plan)
			return;
		_calculateNextMeal(plan);
	}, [userCurrentPlan])

	React.useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: Q_GET_CALORIES,
				variables: {'day': moment().format('YYYY-MM-DD')}
			}
		})
		.then((res) => {
			dispatch({
				type: 'setDayCalories',
				dayCaloriesLog: res.data.UserGetCaloriesDay
			});
		})
		.catch((err) => {
			console.error(err);
		});
	}, []);

	const _calculateNextMeal = (plan) => {
		var d = new Date();
		// for us Sunday is 6 instead of 0
		var weekdayIndex = d.getDay() - 1 >= 0 ? d.getDay() - 1 : 6;
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var dayplan = plan.menus[weekdayIndex];
		var meal;
		if(hours >= 0 && hours < 12) {
			meal = "breakfast";
		}
		else if(hours >= 12 && hours < 17) {
			meal = "lunch";
		}
		else if(hours >= 17 && minutes > 0) {
			meal = "dinner";
		}
		dayplan.map(recipe => {
			if(recipe.meal === meal) {
				setNextMeal(recipe);
			}
			return true;
		});
	}

	const _onLogMeal = (newCaloriesEaten) => {
		dispatch({
			type: 'setDayCalories',
			dayCaloriesLog: newCaloriesEaten
		});
		window.analytics.track('Recipe eaten', { 'surface': 'home' });
	}

	return(
		<VYPageWrapper>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Home</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonGrid fixed className={styles.widgetsList}>
					<IonRow>
						<IonCol sizeMd="6" offsetMd="3">
							{nextMeal && 
								<WidgetNextMeal meal={nextMeal} onLog={_onLogMeal} />
							}
							{(!state.user || !state.user.isPremium) &&
								<MembershipBanner margin/>
							}
							<WidgetGrocery grocery={state.grocery}/>
							<WidgetCalories calories={state.dayCaloriesLog/NUTRITION_BASE}/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</VYPageWrapper>
	)
}

export default Home