export default (value) => {
    var className = "high";

    if(typeof value !== 'string')
        return null;

    if(value.toLowerCase() === 'effortless') {
        className = 'low';
    }
    if(value.toLowerCase() === 'easy') {
        className = 'low';
    }
    if(value.toLowerCase() === 'moderate') {
        className = 'medium';
    }
    if(value.toLowerCase() === 'challenging') {
        className = 'high';
    }
    return className;
}