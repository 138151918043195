import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types";
import { IonList, IonListHeader, IonButton, IonIcon, IonLabel, IonAlert, IonActionSheet } from "@ionic/react"
import { add, swapHorizontalOutline, trash, informationCircleOutline, remove } from "ionicons/icons";
import SkeletonPlan from "../../components/SkeletonPlan"
import RecipeItem from "../../components/RecipeItem"
import ToWeekday from "../../utils/ToWeekday"
import FetchClient from "../../utils/FetchClient"
import GroceryUtil from "../../utils/GroceryUtil"
import StatsPanel from "./StatsPanel"
import styles from "./PlanDetail.module.css"

import { AppContext } from "../../AppContext"

const Q_FRAGMENT = `
	id
	title
	menus {
		id
		assocId
		title
		thumb
		meal
		preptime
		difficulty
		rp_calories
		rp_fat
		rp_saturates
		rp_protein
		rp_carbohydrates
		rp_sugars
		rp_fibre
		rp_salt
		tags
	}
    nutrition {
		calories
		fat
		saturates
		protein
		carbohydrates
		sugars
		fibre
		salt
		group_id
	}
	updated_at
`;

const GQUERY = `
    query($id: Int) {
        findPlan(id: $id) {
			${Q_FRAGMENT}
		}
    }
`;

const Q_REMOVE = `
	mutation($plan: Int!, $associd: Int) {
		UserEditPlan(plan: $plan, action: "remove", associd: $associd) {
			${Q_FRAGMENT}
		}
	}
`;

var nutritionBase = process.env.REACT_APP_NUTRITION_BASE;

export default function PlanDetail(props) {
	const [weekplan, setWeekplan] = useState(null);
	const [selectNewMeal, setSelectNewMeal] = useState(null);
	const [pendingRemove, setPendingRemoval] = useState(false);
	const [showNutrition, setShowNutrition] = useState(false);

	const history = useHistory();
	const { state, dispatch } = useContext(AppContext);
	
	useEffect(() => {
		_initialState();
		_initGrocery();
	}, [props.plan])


	useEffect(() => {
		// Ionic does not unmount the component when moving pages
		// in order to keep faster page load, so we use events
		// to syncronise the data when the user performs actions in the preview
		// which affects the list of plans
		if(state.editedPlan) {
			const parsed = JSON.parse(state.editedPlan);
			// if this is the plan edited, update
			if(parsed && parsed.id === weekplan.id) setWeekplan(parsed);
		}
		return function cleanup() {
			dispatch({ type: 'setEditedPlan', plan: null });
		}
	}, [ state['editedPlan'] ])

	const _initialState = async () => {
		const { plan } = props;

		if(typeof plan === "number") {
			FetchClient({
				method: 'post',
				data: {
					query: GQUERY,
					variables: {id: plan}
				}
			})
			.then((res) => {
				setWeekplan(res.data.findPlan);
			})
			.catch((err) => {
				console.error(err);
			});
		} 
		else if(typeof plan === "object") {
			setWeekplan(plan);
		}
		else {
			// TODO error message
			return;
		}
	}

	const _initGrocery = async () => {
		const { plan } = props;
		// load grocery list from state
		GroceryUtil.list = state.grocery;
		// init the grocery list utility
		// - it creates an empty grocery array for the given plan
		//   none exists already
		await GroceryUtil.init(typeof plan === "number" ? plan : plan.id);
		/*
		dispatch({
			type: 'setGrocery',
			grocery: list
		})
		*/
	}

	/**
	 * Adds or removes a recipe's ID to/from the grocery list
	 * 
	 * @param itemID 
	 * @param dayIndex 
	 * @param e 
	 */
	const _addOrRemoveGrocery = async (itemID) => {
		try {
			const list = GroceryUtil.addOrRemoveItem(itemID);
			// this will trigger a state update and 
			// have AppContext persist the updated state in local storage
			dispatch({
				type: 'setGrocery',
				grocery: list
			});
		} catch(err) {
			// if the above throws an error,
			// initialize the grocery list for this plan...
			_initGrocery();
			// ...then perform the add/remove op
			const list = GroceryUtil.addOrRemoveItem(itemID);
			// this will trigger a state update and 
			// have AppContext persist the updated state in local storage
			dispatch({
				type: 'setGrocery',
				grocery: list
			});
		}
		window.analytics.track('Grocery actions', { 'plan': weekplan.id, 'action': 'add/remove' });
	}

	const _gotoSearch = (swappedRecipe, day, meal) => {
		history.push(`/search/recipes?plan=${weekplan.id}&swap=${swappedRecipe}&d=${day}&m=${meal}`, { direction: 'forward' });
	}

	/**
	 * 
	 * @param {*} itemID 
	 * @param {*} dayIndex 
	 * @param {*} action 
	 */
	const _removeMeal = async () => {
		const [ assocId, dayIndex, mealIndex ] = pendingRemove;
		weekplan.menus[dayIndex].splice(mealIndex, 1);
		setWeekplan(Object.assign({}, weekplan));
		FetchClient({
			method: 'post',
			data: {
				query: Q_REMOVE,
				variables: {
					'plan': weekplan.id,
					'associd': assocId
				}
			}
		})
		.then((res) => {
			setWeekplan(res.data.UserEditPlan);
			setPendingRemoval(false);
		})
		.catch((err) => {
			console.error(err);
		});
		window.analytics.track('Plan edit', { 'plan': weekplan.id, 'action': 'remove' });
	}

	/**
	 * 
	 */
	const _toggleNutritionPanel = (key = false) => {
		setShowNutrition(key);
		window.analytics.track('Plan toggle nutrition', { 'plan': weekplan.id });
	}

	// we need weekplan loaded fetch call 
	// and user loaded from state to use this page
	const isFullyLoaded = weekplan && state.user;

	return(
		<>
			{!isFullyLoaded &&
				<SkeletonPlan/>
			}
			{isFullyLoaded &&
				<>
					{weekplan.menus.map((day, index) => (
						<IonList key={index} className={styles.dayplanList}>
							<IonListHeader mode="md" className={styles.dayplanHeader}>
								<IonLabel>{ToWeekday(index)}</IonLabel>
								<span className={styles.calBtn} onClick={_toggleNutritionPanel.bind(this, index)}>
									<IonIcon icon={informationCircleOutline}/>
									{parseInt(weekplan.nutrition[index].calories/nutritionBase)} Kcal
								</span>
							</IonListHeader>
							{day.map((recipe, nIndex) => (
								<RecipeItem key={recipe.assocId} {...recipe} fromPlan id={recipe.assocId} thumb={recipe.thumb} isLocked={!state.user.isPremium && weekplan.id !== state.user.freePlan} footerCta={
									props.groceryMode ?
										<>
											<IonButton fill="clear" size="small" onClick={(e) => _addOrRemoveGrocery(recipe.assocId)}>
												<IonIcon slot="start" icon={GroceryUtil.hasItem(recipe.assocId) ? remove : add}/>
												Grocery list
											</IonButton>
										</>
									: props.editMode ?
										<>
											<IonButton fill="clear" size="small" onClick={(e) => _gotoSearch(recipe.assocId, index, recipe.meal)}>
												<IonIcon slot="start" icon={swapHorizontalOutline}/>
												Swap
											</IonButton>
											<IonButton fill="clear" size="small" onClick={(e) => setPendingRemoval([recipe.assocId, index, nIndex])}>
												<IonIcon slot="start" icon={trash}/>
												Remove
											</IonButton>
										</>
									: <></>
								}/>
							))}
							{day.length < 1 &&
								<div className={styles.emptyDayPlacelholder}>
									<span className="caption">Nothing planned for this day</span>
								</div>
							}
							{props.editMode &&
								<div className={styles.actionsWrapper}>
									<button className={styles.addMealBtn} onClick={(e) => setSelectNewMeal(index)}>
										Add new recipe
									</button>
								</div>
							}	
							<StatsPanel key={index} show={showNutrition === index} nutrition={weekplan.nutrition[index]} title={ToWeekday(index)} onClose={_toggleNutritionPanel}/>
						</IonList>
					))}
					<IonActionSheet 
						header="What do you want to add?"
						isOpen={selectNewMeal !== null}
						onDidDismiss={() => {
							setSelectNewMeal(null);
						}}
						buttons={[
							{
								text: 'Breakfast',
								handler: () => _gotoSearch(null, selectNewMeal, 'breakfast')
							},
							{
								text: 'Lunch',
								handler: () => _gotoSearch(null, selectNewMeal, 'lunch')
							},
							{
								text: 'Dinner',
								handler: () => _gotoSearch(null, selectNewMeal, 'dinner')
							},
							{
								text: 'Snack',
								handler: () => _gotoSearch(null, selectNewMeal, 'snack')
							}
						]}
					/>				
					<IonAlert
						isOpen={pendingRemove !== false}
						onDidDismiss={() => console.log()}
						header={'Confirm removal'}
						message={"Are you sure you want to remove this from your plan? This action can't be undone."}
						buttons={[
							{
								text: "Cancel",
								role: "cancel",
								handler: () => {
									setPendingRemoval(false)
								}
							},
							{
								text: "Remove",
								handler: () => {
									_removeMeal()
								}
							},
						]}
					/>
				</>
			}
		</>
	)
}

PlanDetail.propTypes = {
	plan: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	groceryMode: PropTypes.bool,
	editMode: PropTypes.bool
}