import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { IonGrid, IonRow, IonCol } from "@ionic/react"
import SkeletonPlanItem from "../../components/SkeletonPlanItem"
import PlanItem from "../../components/PlanItem";
import FetchClient from "../../utils/FetchClient";
import styles from "./PagePlans.module.css";

const GQUERY = `
    query {
		veglyPlans: listPlans {
			results {
				id
				title
				rendered_title
				menus {
					id
					assocId
					title
					meal
				}
				publishing_status
			}
		}
    }
`;

function SegmentStore() {
	const [plans, setPlans] = React.useState(undefined);
	
	const history = useHistory();
    
	useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: GQUERY,
			}
		})
		.then((res) => {
			setPlans(res.data.veglyPlans.results);
		})
		.catch((err) => {
			console.error(err);
		});
	}, [])
	
	const _gotoPreview = (selectedPlan, title = null) => {
		history.push(`/plans/preview/${selectedPlan}`, { 'referrer': 'plansStore', 'title': title })
	}

	return(
		<IonGrid fixed>
			<IonRow className={styles.segmentStore}>
				<IonCol sizeMd="6" offsetMd="3">
					{!plans ?
						<>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
						</>
					:
						plans.map((plan, index) => (
							<PlanItem 
								key={index} 
								title={plan.rendered_title || plan.title}
								isNew={plan.publishing_status === 'scheduled'}
								onClick={_gotoPreview.bind(this, plan.id, plan.rendered_title || plan.title)}
							/>
						))
					}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}

export default SegmentStore