import React , { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { IonIcon, IonChip, IonLabel, IonGrid, IonCol, IonRow } from '@ionic/react';
import SkeletonPlanItem from "../../components/SkeletonPlanItem"
import PlanItem from "../../components/PlanItem"
import PageEmptyPlaceholder from "../../components/PageEmptyPlaceholder";
import { useUserPlans } from "../../hooks";
import { checkmarkCircle, bookmarks } from "ionicons/icons";
import styles from "./PagePlans.module.css";

import { AppContext } from "../../AppContext"

function SegmentArchives() {
	const [ sync, setSync ] = useState(null); 
	const { userPlans, currentPlan, isLoading, isError } = useUserPlans(sync);
	
	const history = useHistory();
	const { state, dispatch } = useContext(AppContext);

	useEffect(() => {
		// Ionic does not unmount the component when moving pages
		// in order to keep faster page load, so we use events
		// to syncronise the data when the user performs actions in the preview
		// which affects the list of plans
		if(state.lastEvent === 'changed_plan' || state.lastEvent === 'renamed_plan' || state.lastEvent === 'deleted_plan') {
			setSync(new Date());
		}
		return function cleanup() {
			dispatch({ type: 'setSync', event: null });
		}
	}, [state['lastEvent']])

	const _gotoPreview = (selectedPlan, title = null) => {
		history.push(`/plans/preview/${selectedPlan}`, { 'direction': 'forward', 'referrer': 'plansArchives', 'title': title })
	}

	return(
		<IonGrid fixed>
			<IonRow>
				<IonCol sizeMd="6" offsetMd="3">
					{
					isLoading ? 
						<>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
							<SkeletonPlanItem/>
						</>
					: 
					userPlans.length > 0 ?
						userPlans.map((plan, index) => (
							<PlanItem 
								key={index} 
								title={plan.title} 
								description={currentPlan && currentPlan.id === plan.id ? 
									<IonChip outline color="primary" className={styles.cardChip}>
										<IonIcon icon={checkmarkCircle}/>
										<IonLabel>Your current plan</IonLabel>
									</IonChip>
								: null}
								updatedAt={plan.updated_at}
								updatedAtLabel="Last updated"
								onClick={_gotoPreview.bind(this, plan.id, plan.title)}
							/>
						))
					:
						<PageEmptyPlaceholder icon={bookmarks} message={
							<>
								<p>You don't have any plans yet.</p>
								<p>Head over to our plans store and choose a plan to get started with</p>
							</>
						}/>
					}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}

export default SegmentArchives