import React from "react"
import { IonFab, IonFabButton, IonIcon } from "@ionic/react"
import FetchClient from "../../utils/FetchClient"
import { restaurant } from "ionicons/icons"
import moment from "moment"

const Q_INIT = `
    query($plan_recipe: Int, $day: String) {
        UserCheckLog(plan_recipe: $plan_recipe, day: $day)
    }
`;

const Q_LOG_MEAL = `
    mutation($id: Int) {
        UserLogDiary(plan_recipe: $id)
    }
`;
/** 
 * recipeId The plan <> recipe assoc Id
 */
export default ({ recipeId: recipeAssocId, onClick, ...rest}) => {
    const [isLogged, setIsLogged] = React.useState();

    React.useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: Q_INIT,
				variables: {
                    plan_recipe: recipeAssocId,
                    day: moment().format("YYYY-MM-DD")
                }
			}
		}).then((res) => {
            setIsLogged(res.data.UserCheckLog);
		}).catch((err) => {
			console.log(err);
		});
    }, [recipeAssocId])

    const _logMeal = () => {
		FetchClient({
			method: 'post',
			data: {
				query: Q_LOG_MEAL,
				variables: {
                    id: recipeAssocId
                }
			}
		}).then((res) => {
            setIsLogged(res.data.UserLogDiary);
            onClick(res.data.UserLogDiary);
		}).catch((err) => {
			console.log(err);
		});
    }

    return isLogged ? (
        <></>
    ) : (
        <IonFab {...rest}>
            <IonFabButton onClick={_logMeal}>
                <IonIcon icon={restaurant} />
            </IonFabButton>
        </IonFab>
    )
}