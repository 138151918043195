import _ from "lodash"

const GroceryUtil = {
    plan: '',
    list: [],

    init: async function (planId) {
        this.plan = planId;
        var planList = _.find(this.list, { 'plan': planId });
        if(!planList) {
            this.list.push({
                'plan': planId,
                'list': []
            });
        }
        return this.list;
    },

    getList: function () {
        return _.find(this.list, { 'plan': this.plan });
    },

    addOrRemoveItem: function (itemId) {
		if(this.list !== null) {
            var planList = _.find(this.list, { 'plan': this.plan });
            var list = planList.list;

			if(list.indexOf(itemId) > -1) {
				// remove from list
				list.splice(list.indexOf(itemId), 1);
			} else {
				// add to list
				list.push(itemId);
            }
			return this.list;
        }
    },

    hasItem: function (itemId) {
        var planList = _.find(this.list, { 'plan': this.plan });
        if(!planList) return false;
        return planList.list ? planList.list.indexOf(itemId) > -1 : false;
    },

    flatten: function () {
        return _.flatMap(this.list, (k) => { return _.flatten(k.list) })
    },

    store: async function () {},

    clear: function () {}
};

export default GroceryUtil;