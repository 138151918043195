import React from "react"
import CalorieDonut from "../CaloriesDonut"
import styles from "./NutritionSheet.module.css"

var nutritionBase = process.env.REACT_APP_NUTRITION_BASE;

const ProgressBar = ({ progress }) => (
    <div className={styles.progressBar}>
        <span className={styles.bar}>
            <span className={styles.progress} style={{width: `${parseInt(progress)}%`}}></span>
        </span>
    </div>
)

const StatsPanel = ({ nutrition }) => {

    const calories = (nutrition.calories/nutritionBase).toFixed();
    const carbohydrates = (nutrition.carbohydrates/nutritionBase).toFixed(2);
    const fat = (nutrition.fat/nutritionBase).toFixed(2);
    const fibre = (nutrition.fibre/nutritionBase).toFixed(2);
    const protein = (nutrition.protein/nutritionBase).toFixed(2);
    const salt = (nutrition.salt/nutritionBase).toFixed(2);
    const saturates = (nutrition.saturates/nutritionBase).toFixed(2);
    const sugars = (nutrition.sugars/nutritionBase).toFixed(2);
    
    return(
        <>
            <div className={styles.headSection}>
                <div className={styles.subheader}>
                    <p>Nutritional info</p>
                </div>
                <CalorieDonut count={Math.round(calories)} progress={(calories*100)/2000}/>
            </div>
            <div className={styles.contentSection}>
                <ul>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Fat</span> {fat}g</p>
                            <p className="caption">max 70g</p>
                        </span>
                        <ProgressBar progress={calcProgress(fat, 70)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Saturates</span> {saturates}g</p>
                            <p className="caption">max 20g</p>
                        </span>
                        <ProgressBar progress={calcProgress(saturates, 20)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Carbohydrates</span> {carbohydrates}g</p>
                            <p className="caption">min 260g</p>
                        </span>
                        <ProgressBar progress={calcProgress(carbohydrates, 260)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Sugars</span> {sugars}g</p>
                            <p className="caption">max 90g</p>
                        </span>
                        <ProgressBar progress={calcProgress(sugars, 90)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Fibre</span> {fibre}g</p>
                            <p className="caption">min 30g</p>
                        </span>
                        <ProgressBar progress={calcProgress(fibre, 30)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Protein</span> {protein}g</p>
                            <p className="caption">min 50g</p>
                        </span>
                        <ProgressBar progress={calcProgress(protein, 50)}/>
                    </li>
                    <li className={styles.nutritionItem}>
                        <span>
                            <p><span className="caption">Salt</span> {salt}g</p>
                            <p className="caption">max 6g</p>
                        </span>
                        <ProgressBar progress={calcProgress(salt, 6)}/>
                    </li>
                </ul>
            </div>
            <div className={styles.disclaimer}>
                <p className="caption">The minimum and maximum amounts are based on UK daily reference intakes for the average adult aged 19 to 64.</p>
                <p className="caption"><a href="//www.nhs.uk/live-well/eat-well/what-are-reference-intakes-on-food-labels/" target="_blank" rel="noopener noreferrer">Learn more about reference intakes</a></p>
            </div>
        </>
    )
}

export default StatsPanel

function calcProgress(value, ref) {
    return (value*100)/ref
}