import React from "react"
import { useHistory } from "react-router-dom"
import styles from "./MembershipBanner.module.css"

function MembershipBanner({ margin }) {
    const history = useHistory();

    return(
        <div className={[styles.banner, margin ? styles.margin : ''].join(' ')}>
            <span className={styles.subheader}>Become a member</span>
            <p>Get unlimited access with a Vegly pass</p>
            <button onClick={() => history.push('/subscription', { direction: "forward" })}>Find out more</button>
        </div>
    )
}

export default MembershipBanner;