import React, { useState } from "react"
import { IonModal, IonContent, IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonIcon, IonTextarea } from "@ionic/react"
import { close } from "ionicons/icons"
import styles from "./FeedbackWidget.module.css"

function FeedbackWidget({ onSubmit, show, onClose }) {
    const [ isActive, setActive ] = useState(false);
    const [ input, setInput ] = useState(null);

    const _onBlur = () => setActive(false);

    const _onFocus = () => setActive(true);

    const _onChange = (e) => {
        let { value } = e.target;
        setInput(value);
    }

    const _onSubmit = (e) => {
        e.preventDefault();
        onSubmit(input);
    }

    return(
        <IonModal className={styles.widget} isOpen={show}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>
                            <IonIcon slot="icon-only" icon={close}/>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Help improve Vegly</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form onSubmit={_onSubmit}>
                    <IonTextarea
                        className={isActive ? styles.activeTextbox : ''}
                        placeholder="Got a question? Have you got an idea to improve Vegly? What do you already like? Let us know here"
                        rows={15}
                        required
                        onIonBlur={_onBlur}
                        onIonFocus={_onFocus}
                        onIonChange={_onChange}
                    />
                    <IonToolbar>
                        <IonButton expand="block" type="submit">Submit</IonButton>
                    </IonToolbar>
                </form>
            </IonContent>
        </IonModal>
    )
}

export default FeedbackWidget