import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IonButton, IonContent, IonPage, IonSlides, IonSlide } from "@ionic/react"
import styles from "./PageOnboarding.module.css"

const s3assetsPath = "//eatvegly-lon.s3.eu-west-2.amazonaws.com/app-assets";
const onboardingOne = `${s3assetsPath}/onboarding_one@2x.png`;
const onboardingTwo = `${s3assetsPath}/onboarding_two@2x.png`;
const onboardingThree = `${s3assetsPath}/onboarding_three@2x.png`;
const onboardingFour = `${s3assetsPath}/onboarding_four@2x.png`;
const onboardingFive = `${s3assetsPath}/onboarding_five@2x.png`;
const onboardingSix = `${s3assetsPath}/onboarding_six@2x.png`;
const onboardingSeven = `${s3assetsPath}/onboarding_seven@2x.png`;

//width={375} height={364}

function CoverWrapper({ img, children }) {
    return(
        <div className={styles.body}>
            <div className={styles.cover}>
                <img src={img} srcSet={`${img} 2x`}/>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

function Onboarding() {
    const slides = useRef(null);
    const history = useHistory();
    const [cursor, setCursor] = useState(1);

    const slidesLength = 7;

    useEffect(() => {
        window.analytics.page('Onboarding');
        /*
        // if the document has the cookie domain in the referrer
        // the user was redirected from the landing page
        // track a signup
        const referrer = document.referrer;
        if(referrer && referrer.indexOf(process.env.REACT_APP_COOKIE_DOMAIN) > -1) {
            window.analytics.track('Signed up', { 'surface': 'landing' })
        }
        */
    }, []);
    
    const _onPrev = () => {
        slides.current.slidePrev();
        if(cursor > 1) setCursor(cursor - 1);
        window.analytics.track('Onboarding prev', { 'slide': cursor });
    }

    const _onNext = () => {
        slides.current.slideNext();
        if(cursor < slidesLength) setCursor(cursor + 1);
        window.analytics.track('Onboarding next', { 'slide': cursor });
    }

    const _onClose = () => {
        window.analytics.track(cursor === slidesLength ? 'Onboarding complete' : 'Onboarding skipped', { 'lastSlide': cursor });
        history.push('/tabs/plans', { 'direction': 'forward' });
    }

    return(
        <IonPage className={styles.onboarding}>
            <IonContent>
                <div className={styles.headerActions}>
                    <span>
                        { cursor !== slidesLength &&
                        <IonButton fill="clear" onClick={_onClose}>Skip</IonButton>
                        }
                    </span>
                </div>
                <IonSlides ref={slides} pager={true}>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingOne}>
                            <h1>Welcome to Vegly!</h1>
                            <p>Let’s get you on your way to a healthy and delicious vegetarian lifestyle</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingTwo}>
                            <h1>Plan</h1>
                            <p>Your plan has all the recipes and snacks for the week. Click the calorie count to see all the balanced nutritional information for each day.</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingThree}>
                            <h1>Customise</h1>
                            <p>Customise your plan to suit your needs. Remove or swap items using our large library of delicious recipes.</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingFour}>
                            <h1>Grocery list</h1>
                            <p>When you’re happy with your plan, add recipes to your grocery list, review and use it when you go grocery shopping. It’s all categorised by aisle to make it easy as pie.</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingFive}>
                            <h1>Cook</h1>
                            <p>When you’ve got your ingredients, use the recipes as you go to make cooking a cinch, and log recipes as eaten to track your calories each day.</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingSix}>
                            <h1>Plan ahead</h1>
                            <p>Plan for future weeks by copying more plans that you like to your list and customise them to create a library of your own personalised plans. You can also use them to plan your shopping in advance.</p>
                        </CoverWrapper>
                    </IonSlide>
                    <IonSlide className={styles.slide}>
                        <CoverWrapper img={onboardingSeven}>
                            <h1>Dig in!</h1>
                            <p>We hope you enjoy planning your meals with us! If you need any help or have feedback for us, you’re always welcome to get in touch by replying to any of our emails.</p>
                        </CoverWrapper>
                    </IonSlide>
                </IonSlides>
                <div className={styles.footerActions}>
                    <span>
                        { cursor !== 1 ?
                            <IonButton fill="clear" onClick={_onPrev}>Prev</IonButton>
                            :
                            <>&nbsp;</>
                        }
                        { cursor < slidesLength ?
                            <IonButton fill="clear" onClick={_onNext}>Next</IonButton>
                            :
                            <IonButton fill="solid" color="primary" onClick={_onClose}>Start!</IonButton>
                        }
                    </span>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Onboarding