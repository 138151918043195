import React from "react"
import { IonButton, IonIcon } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { arrowBack } from "ionicons/icons"


function VYBackButton() {
    const history = useHistory();

    const _onClick = () => {
        history.goBack();
    }

    return(
        <IonButton onClick={_onClick}>
            <IonIcon slot="icon-only" icon={arrowBack} />
        </IonButton>
    )
}

export default VYBackButton