import React, { useEffect, useState } from "react"
import { IonPage, IonCheckbox, IonContent, IonItem, IonInput, IonButton, IonLabel, IonSpinner, IonToast, IonGrid, IonRow, IonCol } from "@ionic/react"
import { Link, Redirect, useParams, useHistory } from "react-router-dom"
import FetchClient from "../../utils/FetchClient"
import Auth from "../../utils/Auth"
import styles from "./PageReset.module.css"

var errorMsg;
var successMsg;

function PageReset(props) {
    const { tok, uid } = useParams();
    const history = useHistory();

    const [isRequest] = useState(tok === undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [input, setInput] = useState({});

    const _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = value;
        setInput(Object.assign({}, input, object));
    }

    const _onReset = (e) => {
        e.preventDefault();
        setIsLoading(true);

        var data;

        if(isRequest) {
            data =  {
                'email': input.uname
            };
        } else {
            data =  {
                'password': input.upwd,
                'confirm_password': input.upwd_confirm,
                'uid': uid,
                'reset_token': tok
            };
        }

        FetchClient({
            method: 'put',
            data: data,
            url: `${process.env.REACT_APP_REST_HOST}/auth/reset`
        })
        .then(res => {
            if(isRequest) {
                successMsg = `We sent an email to ${input.uname}, click the link in the message to reset your password`;
            } else {
                successMsg = `Password changed successfully. Now you can sign in with using your new password`;
                setTimeout(() => {
                    history.push('/signin', { 'direction': 'forward' });
                }, 1000)
            }
            // cleanup
            setIsLoading(false);
            setIsSuccess(true);
        })
        .catch(err => {
            err.response.json().then(parsed => {
                setIsLoading(false);
                switch(err.response.status) {
                    case 400:
                        errorMsg = typeof parsed.message === 'object' ? Object.keys(parsed.message).map(k => `${parsed.message[k]} `) : parsed.message;
                        break;
                    case 404:
                        errorMsg = "We couldn't find an account with this email address"
                        break;
                    default:
                        errorMsg = "An error occured. Please check the details provided and try again.";
                }
                setHasError(true);
            })
        })
    }

    if(Auth.isAuthenticated()) {
        return <Redirect to="/tabs/home"/>;
    }

    return(
        <IonPage>
            <IonContent className={styles.contentWrapper}>
                <header>
                    {isRequest ? 
                        <>
                            <h1>Reset password</h1>
                            <p>We'll send instructions to reset your password to your email</p>
                        </>
                    :
                        <>
                            <h1>Change your password</h1>
                            <p>Type in the new password and confirm it</p>
                        </>
                    }
                </header>
                <IonGrid fixed>
                    <IonRow>
                        <IonCol sizeMd="6" offsetMd="3">
                            <form onSubmit={_onReset}>
                                {isRequest &&
                                    <IonItem lines="full" className={styles.formInputGroup}>
                                        <IonInput name="uname" type="email" placeholder="Email address" required value={input['uname'] || ''} onIonChange={_onChange}/>
                                    </IonItem>
                                }
                                {!isRequest &&
                                    <>
                                        <IonItem lines="full" className={styles.formInputGroup}>
                                            <IonInput name="upwd" type="password" placeholder="New password" required value={input['upwd'] || ''} onIonChange={_onChange}/>
                                        </IonItem>
                                        <IonItem lines="full" className={styles.formInputGroup}>
                                            <IonInput name="upwd_confirm" type="password" placeholder="Confirm new password" required value={input['upwd_confirm'] || ''} onIonChange={_onChange}/>
                                        </IonItem>
                                    </>
                                }
                                <div className={styles.formActions}>
                                    {isLoading ? 
                                        <span className={styles.spinnerContainer}>
                                            <IonSpinner name="crescent"/>
                                        </span>
                                        :
                                        <IonButton type="submit" expand="block">{isRequest ? "Send reset link" : "Change password"}</IonButton>
                                    }
                                </div>
                            </form>
                            {isRequest &&
                                <span className={styles.formLink}>Don't have an account?&nbsp;<Link to="/signup">Register here</Link></span>
                            }
                            <IonToast
                                isOpen={hasError}
                                onDidDismiss={() => setHasError(false)}
                                message={errorMsg}
                                position="bottom"
                                duration={5000}
                                color="warning"
                                className={styles.toast}
                            />
                            <IonToast
                                isOpen={isSuccess}
                                onDidDismiss={() => setIsSuccess(false)}
                                message={successMsg}
                                position="bottom"
                                duration={5000}
                                color="dark"
                                className={styles.toast}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default PageReset