import React from 'react';
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router"
import { PageCheckoutSuccess, PageOnboarding, PagePreviewPlan, PageRecipeDetail,PageReset, PageSignin, PageSubs, PageEditPassword, PageRecipeSearch, PageSignout, PageVerify } from "./pages"
import "./App.css";
import { AppContextProvider } from "./AppContext"
import Auth from "./utils/Auth"
import TabBar from './pages/TabBar';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={props => (
        Auth.isAuthenticated() ? (
            <Component { ...props } />
        ) : (
            <Redirect to="/signin"/>
        )
    ) } />
);

/*
const PublicRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={props => (
        Auth.isAuthenticated() ? (
            <Component { ...props } />
        ) : (
            <Redirect to="/signin"/>
        )
    ) } />
);
*/

function App() {
	return (
		<IonApp>
            <AppContextProvider>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <PrivateRoute exact path="/getstarted" component={PageOnboarding}/>
                        <PrivateRoute path="/tabs" component={TabBar}/>
                        <PrivateRoute exact path="/search/recipes" component={PageRecipeSearch}/>
                        <PrivateRoute path="/recipes/:id/:isFromPlan?" component={PageRecipeDetail}/>
                        <PrivateRoute exact path="/plans/preview/:id" component={PagePreviewPlan}/>
                        <PrivateRoute exact path="/account/edit_password" component={PageEditPassword}/>
                        <Route path="/" component={PageSignin} exact/>
                        <Route path="/signin" component={PageSignin} exact/>
                        <Route path="/signup" component={PageSignin} exact/>
                        <Route path="/logout" component={PageSignout} exact/>
                        <Route path="/reset/:tok?/:uid?" component={PageReset} exact/>
                        <Route path="/verify/:hash" component={PageVerify} exact/>
                        <Route path="/subscription" component={PageSubs} exact/>
                        <Route exact path="/checkout_success/:session_id" component={PageCheckoutSuccess}/>
                        {/** Fallback for old app URL after login */}
                        <Route path="/weekplan" render={() => <Redirect to="/tabs/home"/>}/>
                    </IonRouterOutlet>
                </IonReactRouter>
            </AppContextProvider>
		</IonApp>
	);
}

export default App;
