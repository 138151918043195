import React, { useEffect } from "react"
import { Redirect } from 'react-router-dom';
import Auth from "../utils/Auth"

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

function PageSignout(props) {

    useEffect(() => {
        // in Ionic components remains mounted
        // so fire only when location matches react router url
        if(props.location && props.location.pathname === "/logout") {
            Storage.clear()
            Auth.signout();
            window.analytics.page('Signed out');
        }
        return function cleanup() {}
    });
    
    return <Redirect to="/" />;
}

export default PageSignout