import { useEffect, useState } from "react"
import FetchClient from "../utils/FetchClient"

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const GET_GROCERY = `
    query($recipes: [Int]) {
        groceries: GroceryGet(recipes: $recipes) {
            category
            category_slug
            ingredients {
                id
                name
                rendered_name
                qty
                unit
            }
        }
    }
`;

const GET_GROCERY_FLAT = `
    query($recipes: [Int]) {
        groceries: GroceryGetFlat(recipes: $recipes) {
            id
            name
            rendered_name
            qty
            unit
        }
    }
`;

export default (flat = false) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const _loadLocalGroceryList = async () => {
        let { value } = await Storage.get({key: 'groceryList'});
        if(value !== null) {
            return JSON.parse(value).flat();
        }
        return []
    }

    useEffect(() => {
        setIsLoading(true);

		_loadLocalGroceryList().then(groceryList => {

			FetchClient({
				method: 'post',
				data: {
					query: flat ? GET_GROCERY_FLAT : GET_GROCERY,
					variables: { recipes: groceryList }
				}
			})
			.then((res) => {		
                setData(res.data.groceries);
                setIsLoading(false);
			})
			.catch((err) => {
                console.error(err);
                setIsError(true);
			});
		});
    }, []);

    return { data, isLoading, isError};
}