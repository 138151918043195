import React from "react"
import { IonIcon } from "@ionic/react"
import styles from "./PageEmptyPlaceholder.module.css"

export default function PageEmptyPlaceholder(props) {
    const { icon, message } = props;
    return(
        <div className={styles.container}>
            <div className={styles.verticalAlign}>
            {icon &&
                <IonIcon className={styles.icon} icon={icon} />
            }
            {typeof message === 'string' ?
                <p>{message}</p>
            :
                <span>{message}</span>
            }
            </div>
        </div>
    )
} 