import { useEffect, useState } from "react"
import FetchClient from "../utils/FetchClient"

const Q_MEDIA = `
    query($id: Int) {
        RecipeFindMedia(id: $id) {
            size
            source_url
        }
    }
`;

export default (recipeId) => {
    const [media, setMedia] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        if(!recipeId)
            return;

        FetchClient({
            method: 'post',
            data: {
                query: Q_MEDIA,
                variables: {'id': recipeId}
            }
        })
        .then((res) => {
            setMedia(res.data.RecipeFindMedia.length > 0 ? res.data.RecipeFindMedia : null);
            setIsLoading(false);
        })
        .catch((err) => {
            console.error(err);
            setIsError(err);
            setIsLoading(false);
        });
    }, [recipeId]);

    return { media, isLoading, isError };
}

