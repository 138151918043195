import React, { useContext } from "react"
import { Route, useRouteMatch } from 'react-router-dom'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { PageAccount, PageGrocery, PageHome, PagePlans } from "../"
import { home, fileTrayFull, list, person, gift } from "ionicons/icons";

import { AppContext } from "../../AppContext"

export default () => {
    const { path } = useRouteMatch();

	const { state } = useContext(AppContext);

    return(
        <IonTabs>
            <IonRouterOutlet>
                <Route path={`${path}/home`} component={PageHome} exact/>
                <Route path={`${path}/plans`} component={PagePlans} exact/>
                <Route path={`${path}/grocery`} component={PageGrocery} exact/>
                <Route path={`${path}/account`} component={PageAccount} exact/>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" mode="md">
                <IonTabButton tab="home" href={`${path}/home`}>
                    <IonIcon icon={home}/>
                    <IonLabel>Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab="plans" href={`${path}/plans`}>
                    <IonIcon icon={fileTrayFull}/>
                    <IonLabel>Plans</IonLabel>
                </IonTabButton>
                <IonTabButton tab="grocery" href={`${path}/grocery`}>
                    <IonIcon icon={list}/>
                    <IonLabel>Grocery list</IonLabel>
                </IonTabButton>
                <IonTabButton tab="account" href={`${path}/account`}>
                    <IonIcon icon={person}/>
                    <IonLabel>Account</IonLabel>
                </IonTabButton>
                {(!state.user || !state.user.isPremium) &&
                    <IonTabButton tab="subscription" href={`/subscription`}>
                        <IonIcon icon={gift}/>
                        <IonLabel>Passes</IonLabel>
                    </IonTabButton>
                }
            </IonTabBar>
        </IonTabs>
    )
}