import React, { useEffect, useState } from "react"
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonButton, IonInput, IonGrid, IonRow, IonCol, IonToast } from "@ionic/react"
import VYBackButton from "../../components/VYBackButton"
import FetchClient from "../../utils/FetchClient"
import styles from "./PageEditPassword.module.css"

var toastMsg = {
    color: 'dark',
    message: ''
}

const GQ = `
	mutation($currentpwd: String!, $newpwd: String!, $confirmpwd: String!) {
		UserChangePassword(currentpwd: $currentpwd, newpwd: $newpwd, confirmpwd: $confirmpwd)
	}
`;

function PageEditPassword(props) {
    const [input, setInput] = useState({});
    const [showToast, setShowToast] = useState(null);

	useEffect(() => {
		window.analytics.page('Account', 'Edit password');
    }, [props.match])
    
    const _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = value;
        setInput(Object.assign({}, input, object));
    }

	const _onSave = (e) => {
        e.preventDefault();

        if(input.new_pwd !== input.confirm_pwd) {
            toastMsg.color = 'warning';
            toastMsg.message = 'New password doesn\'t match';
            setShowToast(true);
            return;
        }

		FetchClient({
			method: 'post',
			data: {
				query: GQ,
				variables: {
                    'currentpwd': input.current_pwd,
                    'newpwd': input.new_pwd,
                    'confirmpwd': input.confirm_pwd
                }
			}
		})
		.then((res) => {
            if(res.errors) {
                throw new Error(res.errors[0].message);
            } else {
                toastMsg.color = 'dark';
                toastMsg.message = 'Your password has been updated';
                setShowToast(true)
                setInput({});
            }
		})
		.catch((err) => {
            console.error(err);
            toastMsg.color = 'warning';
			toastMsg.message = `An error occurred. ${err}`;
            setShowToast(true)
        });
	}

    return(
		<IonPage>
			<IonHeader>
				<IonToolbar>
                    <IonButtons slot="start">
                        <VYBackButton/>
                    </IonButtons>
					<IonTitle>Change password</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
                <form onSubmit={_onSave}>
                    <IonGrid fixed>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked">Current password</IonLabel>
                                    <IonInput name="current_pwd" type="password" required value={input['current_pwd'] || ''} onIonChange={_onChange}></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">New password</IonLabel>
                                    <IonInput name="new_pwd" type="password" required value={input['new_pwd'] || ''} onIonChange={_onChange}></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">Confirm new password</IonLabel>
                                    <IonInput name="confirm_pwd" type="password" required value={input['confirm_pwd'] || ''} onIonChange={_onChange}></IonInput>
                                </IonItem>
                        </IonCol>
                        </IonRow>
                    </IonGrid>
                    <footer className={styles.stickyAction}>
                        <IonButton expand="block" type="submit">Change password</IonButton>
                    </footer>
                </form>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toastMsg.message}
					position="middle"
					duration={5000}
					color={toastMsg.color}
					//className={styles.warningToast}
				/>
			</IonContent>
		</IonPage>
    )
}

export default PageEditPassword