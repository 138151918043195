export default function ToWeekday(number) {
    if(number < 0 || number > 6)
        throw new Error("Invalid number");

    const Weekdays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]
    return Weekdays[number];
}