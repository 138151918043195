/** 
 * App global context
 * - see: https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/ 
 */
import React, { createContext, useEffect, useReducer, useState } from "react"
import { IonAlert } from "@ionic/react"
import Auth from "./utils/Auth"
import FetchClient from "./utils/FetchClient"
import CryptoJS from 'crypto-js';

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const initialState = {
    user: null,
    grocery: [],
    dayCaloriesLog: 0,
    editedPlan: null,
    lastEvent: null,
    errors: null
}

let AppContext = createContext(initialState);

let reducer = (state, action) => {
    switch(action.type) {
        case 'setUser': {
            return { ...state, user: action.user }
        }
        case 'setGrocery': {
            return { ...state, grocery: action.grocery }
        }
        case 'setDayCalories': {
            return { ...state, dayCaloriesLog: action.dayCaloriesLog }
        }
        case 'setEditedPlan': {
            return { ...state, editedPlan: action.editedPlan }
        }
        case 'setSync': {
            return { ...state, lastEvent: action.event }
        }
        case 'setError': {
            return { ...state, errors: action.error }
        }
    }
    return state;
}

function AppContextProvider(props) {
    const [isLoadingState, setIsLoadingState] = useState(true);

    var fullInitialState = {
        ...initialState
    }

    let [state, dispatch] = useReducer(reducer, fullInitialState);

    const _init = async () => {
        let { value } = await Storage.get({ key: '_app_ctx' });
        let persistedState;

        if(value) {
            persistedState = JSON.parse(CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8))
            if(persistedState.grocery)
                // dispatch and persist
                dispatch({ type: 'setGrocery', grocery: persistedState.grocery });
        }

        if(Auth.isAuthenticated()) {
            FetchClient({
                method: 'post',
                data: {
                    query: `
                        query {
                            userProfile: UserGetMe {
                                id
                                email
                                firstname
                                verified
                                freePlan
                                isPremium
                                activeSub
                                pendingCancellation
                            }
                        }
                    `,
                }
            })
            .then((res) => {
                const { userProfile } = res.data;

                window.analytics.identify(`vuid-${userProfile.id}`, {
                    name: `User ${userProfile.id}`,
                    subscription: userProfile.isPremium ? 'member' : 'basic'
                });
                // the loading state makes sure the 
                // state is not persited by the effect below before
                // all async operation are completed
                setIsLoadingState(false);
                // dispatch and persist
                dispatch({
                    type: 'setUser',
                    user: res.data.userProfile
                })
            })
            .catch((err) => {
                console.error(err);
            });
        }
    }

    useEffect(() => {
        _init();
    }, [])

    useEffect(() => {
        if(isLoadingState) return;
        //console.debug("Persisting state...", state)
        // every time the state is updated
        // updated the persisted copy in local storage
        let encryptedState = CryptoJS.enc.Utf8.parse(JSON.stringify(state));
        Storage.set({ key: '_app_ctx', value: CryptoJS.enc.Base64.stringify(encryptedState) });
    }, [state])

    let value = { state, dispatch, isLoadingState };

    // if an "invalid token" error is dispatched
    // users need to re-authenticate and have a new
    // token generated for them
    var hasInvalidToken = false;
    try {
        if(state.errors && state.errors.errors) {
            let err = state.errors.errors[0];
            if(err.message.indexOf('invalid token') > -1) {
                Auth.signout();
                hasInvalidToken = true;
            }
        }
    } catch(err) {
        console.error(err);
    }

    return (
        <AppContext.Provider value={value}>
            {props.children}

            {hasInvalidToken &&
                <IonAlert 
                    isOpen={true} 
                    backdropDismiss={false}
                    message="Your session expired, login again"
                    buttons={[
                        {
                            text: 'Go to sign in page',
                            handler: () => window.location = '/signin'
                        }
                    ]}
                />
            }
        </AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };