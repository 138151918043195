import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, IonChip, IonLabel } from "@ionic/react"
import { arrowForward } from "ionicons/icons"
import styles from "./PlanItem.module.css"

export default function PlanItem(props) {
    const { title, description, updatedAt, updatedAtLabel, isNew, onClick } = props;
    const descBlock = typeof description === "string" ? <p>{description}</p> : <div>{description}</div>
    const published = updatedAt ? moment(Number(updatedAt)) : null;

    return(
        <IonCard mode="md" className={styles.card} onClick={onClick}>
            <IonCardHeader>
                <IonCardTitle className={styles.title}>
                    {title || "Plan card"}
                    { isNew && 
                        <IonChip color="primary" className={styles.newChip}>
                            <IonLabel>New</IonLabel>
                        </IonChip>
                    }
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className={styles.content}>
                <div className={styles.description}>
                    {published && 
                        <p className={styles.date}>{updatedAtLabel || "Published"}: {published.format("MMM D, YYYY")}</p>
                    }
                    {descBlock}
                </div>
                <div className={styles.actions}>
                    <IonButton fill="clear" size="small" className={styles.button}>
                        <span>View plan</span>
                        <IonIcon className={styles.icon} icon={arrowForward} />
                    </IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    )
}

PlanItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}