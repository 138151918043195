import React from "react"
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSkeletonText } from "@ionic/react"
import styles from "./SkeletonPlanItem.module.css"

function SkeletonPlanItem() {
    return(
        <IonCard mode="md" className={styles.card}>
            <IonCardHeader>
                <IonCardTitle>
                    <IonSkeletonText animated style={{ width: '60%' }} />
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonSkeletonText animated style={{ width: '20%' }} />
            </IonCardContent>
        </IonCard>
    )
}

export default SkeletonPlanItem