import React from "react"
import { IonSkeletonText, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel } from "@ionic/react"

function SkeletonMeal() {
    return(
        <IonItem lines="none">
            <IonThumbnail slot="start">
                <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel>
                <h3>
                  <IonSkeletonText animated style={{ width: '50%' }} />
                </h3>
                <p>
                  <IonSkeletonText animated style={{ width: '80%' }} />
                </p>
                <p>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                </p>
            </IonLabel>
        </IonItem>
    )
}

function SkeletonDay() {
    return(
        <IonList>
            <IonListHeader>
                <IonSkeletonText animated style={{width: "20%" }}/>
            </IonListHeader>
            <SkeletonMeal/>
            <SkeletonMeal/>
            <SkeletonMeal/>
        </IonList>
    )
}

function SkeletonPlan() {
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    return(
        <>
            {weekdays.map((day, index) => (
                <SkeletonDay key={index}/>
            ))}
        </>
    )
}

export default SkeletonPlan