import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { IonFab, IonFabButton, IonIcon, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonSpinner, IonToast, IonGrid, IonActionSheet, IonAlert } from "@ionic/react"
import { useParams } from "react-router-dom"
import VYBackButton from "../../components/VYBackButton"
import PlanDetail from "../../components/PlanDetail"
import FloatingButtonText from "../../components/FloatingButtonText"
import UpsellAlert from "../../components/UpsellAlert"
import FetchClient from "../../utils/FetchClient"
import { AppContext } from "../../AppContext"
import { close, lockClosed, ellipsisVerticalOutline } from "ionicons/icons"
import styles from "./PagePreviewPlan.module.css"

const ASSIGN_PLAN_TO_USER = `
	mutation($plan: Int!) {
		UserAssignPlan(plan: $plan, status: "active")
	}
`;

const RENAME_PLAN = `
    mutation($plan: Int!, $title: String!) {
        UserRenamePlan(plan: $plan, title: $title)
    }
`;

const DELETE_PLAN = `
    mutation($plan: Int!) {
        UserDeletePlan(plan: $plan)
    }
`;

var toastOpts = {
    color: 'dark',
    message: 'Your settings have been saved'
}

function PagePreviewPlan(props) {
    const { id } = useParams();

    const [referrer, setReferrer] = useState(false);
    const [pageTitle, setPageTitle] = useState(false);
    const [showMoreActions, setShowMoreActions] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [updatingCurrent, setUpdatingCurrent] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showUpsell, setShowUpsell] = useState(false);
    const [editMode, toggleEditMode] = useState(false);

    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);

    const planID = parseInt(id);
    const isFreePlan = state.user && (state.user.freePlan === planID);

    useEffect(() => {
        if(props.location && props.location.state) {
            setReferrer(props.location.state['referrer']);
            setPageTitle(props.location.state['title']);
        }
    }, []);

    const groceryAlertsFlag = sessionStorage.getItem('_vy_groceryAlerts');

    // only plans in the user archives have the grocery list
    // features enabled
    const hasGrocery = referrer === 'plansArchives' && !editMode;

	useEffect(() => {
		window.analytics.page('Plan preview', { 'plan': id, 'referrerTab': referrer });
	}, [props.match]);

	const _makeActive = (hasConfirmed = false) => {
        if(!hasConfirmed && (state && (state.grocery.length > 0 && groceryAlertsFlag !== 'false'))) {
            // if the grocery list is not empty, ask for confirmation before switching plan
            setShowActiveConfirmation(true);
            return;
        }

		setUpdatingCurrent("loading");
		FetchClient({
			method: 'post',
			data: {
				query: ASSIGN_PLAN_TO_USER,
				variables: { plan: planID }
			}
		})
		.then(() => {
            dispatch({
                type: 'setSync',
                event: 'changed_plan'
            });
            setUpdatingCurrent("success");
            setShowToast(true);
		})
		.catch((err) => {
			console.error(err);
        });
        window.analytics.track('Changed active plan', { 'plan': id });
    }

    const _onConfirmRename = (newName) => {
        setPageTitle(newName);
		FetchClient({
			method: 'post',
			data: {
				query: RENAME_PLAN,
				variables: { 'plan': planID, 'title': newName  }
			}
		})
		.then((res) => {
            dispatch({
                type: 'setSync',
                event: 'renamed_plan'
            });
		})
		.catch((err) => {
			console.error(err);
        });
    }

    const _onConfirmDelete = () => {
		FetchClient({
			method: 'post',
			data: {
				query: DELETE_PLAN,
				variables: { 'plan': planID }
			}
        })
        .then(() => {
            dispatch({
                type: 'setSync',
                event: 'deleted_plan'
            });
        })
		.then((res) => {
            history.push('/tabs/plans', { 'direction': 'backward' });
		})
		.catch((err) => {
			console.error(err);
        });
    }
    
    const _promptUpsell = () => {
        setShowUpsell(true); 
        window.analytics.track('Prompted upsell');
    }

    /**
     * Plans in the user archives can be edited 
     * while in preview mode
     */
	const _toggleEditMode = () => {
        toggleEditMode(!editMode);
        window.analytics.track('Plan edit mode', { 'toggle': !editMode });
    }

    const _openRename = () => {
        setShowRenameModal(true);
    }
    
    const isStatePremium = state.user && state.user.isPremium;

    return(
		<IonPage style={{backgroundColor: "#FFFFFF"}}>
			<IonHeader>
				<IonToolbar>
                    <IonButtons slot="start">
                        <VYBackButton/>
                    </IonButtons>
					<IonTitle>{pageTitle || 'Preview meal plan'}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>	
                <IonGrid fixed>
                    <PlanDetail plan={planID} groceryMode={hasGrocery} editMode={editMode}/>
                </IonGrid>
                {(updatingCurrent !== "success" && !editMode) &&
                    <>
                        {referrer === 'plansStore' && 
                            <FloatingButtonText 
                                icon={isStatePremium ? '' : lockClosed}
                                color={isStatePremium ? 'primary' : 'medium'}
                                label={updatingCurrent !== "loading" ? "Copy to my plans" : <IonSpinner name="crescent" />} 
                                onClick={isStatePremium ? _makeActive : _promptUpsell}
                            />
                        }
                        {referrer === 'plansArchives' && 
                            <>
                                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                                    <IonFabButton onClick={() => setShowMoreActions(true)}>
                                        <IonIcon icon={ellipsisVerticalOutline}/>
                                    </IonFabButton>
                                </IonFab>
                                <IonActionSheet
                                    header={'Actions'}
                                    isOpen={showMoreActions}
                                    onDidDismiss={() => setShowMoreActions(null)}
                                    buttons={[
                                        {
                                            text: 'Edit plan',
                                            cssClass: !isStatePremium && isFreePlan ? styles.hiddenAction : '',
                                            handler: isStatePremium ? _toggleEditMode : _promptUpsell
                                        },
                                        {
                                            text: 'Make current',
                                            cssClass: !isStatePremium && isFreePlan ? styles.hiddenAction : '',
                                            handler: isStatePremium ? _makeActive : _promptUpsell
                                        },
                                        {
                                            text: 'Rename plan',
                                            handler: isStatePremium ? _openRename : _promptUpsell
                                        },
                                        {
                                            text: 'Delete plan',
                                            handler: () => {
                                                if(isFreePlan) {
                                                    toastOpts.color = 'warning';
                                                    toastOpts.message = 'Your free plan cannot be deleted.';
                                                    setShowToast(true);
                                                } else {
                                                    setShowDeleteConfirmation(true);
                                                }
                                            }
                                        },
                                        {
                                            text: 'Close',
                                            handler: () => setShowMoreActions(false)
                                        },
                                    ]}
                                />

                                <IonAlert
                                    isOpen={showActiveConfirmation}
                                    onDidDismiss={() => setShowActiveConfirmation(false)}
                                    header={'Confirm changing plan'}
                                    message={'Your grocery list is not empty. Do you want to clear or keep your grocery list?'}
                                    buttons={[
                                        {
                                            text: 'Clear grocery list',
                                            handler: () => {
                                                sessionStorage.setItem('_vy_groceryAlerts', false);
                                                _makeActive(true);
                                                // clear grocery list
                                                setTimeout(() => {
                                                    dispatch({ type: 'setGrocery', grocery: [] });
                                                    window.analytics.track('Grocery actions', { 'action': 'cleared' });
                                                }, 1000);
                                            }
                                        },
                                        {
                                            text: 'Keep grocery list',
                                            handler: () => {
                                                sessionStorage.setItem('_vy_groceryAlerts', false);
                                                _makeActive(true);
                                            }
                                        },
                                        {
                                            text: 'Cancel',
                                            handler: () => setShowActiveConfirmation(false)
                                        }
                                    ]}
                                />

                                <IonAlert
                                    isOpen={showRenameModal}
                                    onDidDismiss={() => setShowRenameModal(false)}
                                    header={'Rename plan'}
                                    inputs={[
                                        {
                                            name: 'new_title',
                                            type: 'text',
                                            placeholder: 'Type the new name here',
                                            value: pageTitle
                                        }
                                    ]}
                                    buttons={[
                                        {
                                            text: 'Cancel',
                                            handler: () => setShowRenameModal(false)
                                        },
                                        {
                                            text: 'Rename',
                                            handler: (inputs) => _onConfirmRename(inputs.new_title)
                                        }
                                    ]}
                                />

                                <IonAlert
                                    isOpen={showDeleteConfirmation}
                                    onDidDismiss={() => setShowDeleteConfirmation(false)}
                                    header={'Delete plan?'}
                                    message={'Are you sure you want to delete this plan? This action cannot be undone.'}
                                    buttons={[
                                        {
                                            text: 'Delete',
                                            handler: () => _onConfirmDelete()
                                        },
                                        {
                                            text: 'Cancel',
                                            handler: () => setShowDeleteConfirmation(false)
                                        }
                                    ]}
                                />
                            </>
                        }
                    </>
                }
                {editMode &&
                    <FloatingButtonText label="Exit edit mode" icon={close} color="dark" onClick={_toggleEditMode}/>
                }

                <IonToast
                    isOpen={showToast}
                    color={toastOpts.color}
                    message={toastOpts.message}
                    duration={3000}
                />	
                <UpsellAlert show={showUpsell} onCancel={setShowUpsell}/>
			</IonContent>
        </IonPage>
    )
}

export default PagePreviewPlan