import React, { useContext, useEffect, useState } from "react"
import { IonButton, IonPage, IonHeader, IonLabel, IonList, IonListHeader, IonToolbar, IonTitle, IonContent, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from "@ionic/react"
import GroceryListItem from "../../components/GroceryItem"
import PageEmptyPlaceholder from "../../components/PageEmptyPlaceholder"
import FetchClient from "../../utils/FetchClient"
import styles from "./PageGrocery.module.css"
import { cartOutline } from "ionicons/icons"
import _ from "lodash"

import { AppContext } from "../../AppContext"
import GroceryUtil from "../../utils/GroceryUtil" 

/*
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
*/

const GQUERY = `
    query($recipes: [Int], $servings: [Int]) {
        groceries: GroceryGet(recipes: $recipes, servings: $servings) {
			category
			category_slug
			ingredients {
				id
				name
				rendered_name
				qty
				unit
				recipes
			}
        }
    }
`;

const utilFetchGrocery = (list, servings) => {
	return FetchClient({
		method: 'post',
		data: {
			query: GQUERY,
			variables: { 'recipes': list, 'servings': servings }
		}
	});
}

const servingAlertOptions = {
	header: "Adjust servings"
}

function PageGrocery(props) {
	const [grocery, setGrocery] = useState([]);
	const [inPantry, setInPantry] = useState([]);
	const [servings, setServings] = useState(1);

	const { state, dispatch, isLoadingState } = useContext(AppContext);

	GroceryUtil.list = state.grocery;
	var groceryList = GroceryUtil.flatten();

	useEffect(() => {
		window.analytics.page('Grocery');

		utilFetchGrocery(groceryList, servings)
		.then((res) => {		
			setGrocery(res.data.groceries);
		})
		.catch((err) => {
			console.error(err);
		});
	}, [props.match])

	/**
	 * Fetch grocery list when servings are updated
	 */
	useEffect(() => {
		utilFetchGrocery(groceryList, servings)
		.then((res) => {		
			setGrocery(res.data.groceries);
		})
		.catch((err) => {
			console.error(err);
		});
	}, [ state, servings ])

	const _clearGroceryList = () => {
		// updated global app state
		// - this page will also updated as the effect has
		//   state as dependency
		dispatch({ type: 'setGrocery', grocery: [] });
		window.analytics.track('Grocery actions', { 'action': 'cleared' });
	}

	const _loadPantry = async () => {
		/*
        let { value } = await Storage.get({key: 'pantry'});
        if(value !== null) {
            return JSON.parse(value);
		}
		return [];
		*/
	}

	const _addOrRemoveFromPantry = (ingredientID) => {
		const clone = inPantry.slice();
		var i = clone.indexOf(ingredientID);
		if(i > -1) {
			clone.splice(i, 1);
		} else {
			clone.push(ingredientID);
		}
		setInPantry([...clone]);
		//Storage.set({key: 'pantry', value: JSON.stringify(clone)})
	}

	const _onChangeServings = (value) => {
		setServings(value);
		setInPantry([]); // reset crossed out items, temporary workaround
		window.analytics.track('Grocery actions', { 'action': 'servings', 'servings': value });
	}

	const _onToggleRecipesList = () => {
		window.analytics.track('Grocery actions', { 'action': 'toggle_recipes' });
	}

	const isEmpty = !isLoadingState && groceryList.length < 1;

	const renderedList = grocery.map((aisle) => {
		if(aisle.ingredients) {	
			// alphabetical order by aisle
			var ordered = _.orderBy(aisle.ingredients, ['rendered_name', 'name'], 'asc');
			return(
				<IonList key={aisle.category_slug}>
					<IonListHeader className={styles.listHeader}>
						<IonLabel className="subtitle">{aisle.category}</IonLabel>
					</IonListHeader>
					{ordered.map((ingredient) => <GroceryListItem key={`${ingredient.id}`} {...ingredient} isSelected={inPantry.indexOf(ingredient.id) > -1} onClick={_addOrRemoveFromPantry} onShowRecipes={_onToggleRecipesList}/>)}
				</IonList>
			)
		}
	});

    return(
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Grocery list</IonTitle>
				</IonToolbar>
				<div className={styles.groceryToolbar}>
					<span className={styles.servingsSelector}>
						<IonLabel>Servings:</IonLabel>
						<IonSelect 
							interfaceOptions={servingAlertOptions}
							value={servings} 
							placeholder={`Change servings`}
							onIonChange={e => _onChangeServings(e.detail.value)}
						>	
							<IonSelectOption value={1}>1 serving</IonSelectOption>
							<IonSelectOption value={2}>2 servings</IonSelectOption>
							<IonSelectOption value={3}>3 servings</IonSelectOption>
							<IonSelectOption value={4}>4 servings</IonSelectOption>
							<IonSelectOption value={5}>5 servings</IonSelectOption>
							<IonSelectOption value={6}>6 servings</IonSelectOption>
							<IonSelectOption value={7}>7 servings</IonSelectOption>
							<IonSelectOption value={8}>8 servings</IonSelectOption>
							<IonSelectOption value={9}>9 servings</IonSelectOption>
							<IonSelectOption value={10}>10 servings</IonSelectOption>
						</IonSelect>
					</span>
					<IonButton fill="clear" onClick={_clearGroceryList}>Clear list</IonButton>
				</div>
			</IonHeader>
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol sizeMd="6" offsetMd="3">
							{isEmpty ?
								<PageEmptyPlaceholder 
									icon={cartOutline}
									message={"Your grocery list is empty. Go to your plan and add something to the list."} 
								/>
								:
								renderedList
							}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
    )
}

export default PageGrocery