import React, { useEffect, useState } from "react"
import { Redirect, useParams } from "react-router-dom"
import { IonPage, IonContent } from "@ionic/react"
import FetchClient from "../../utils/FetchClient"

var errorMsg;

function PageVerify() {
    const { hash } = useParams();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ hasError, setHasError ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        FetchClient({
            method: 'put',
            data: { hash },
            url: `${process.env.REACT_APP_REST_HOST}/auth/verify`
        })
        .then(res => {
            window.analytics.page('Verified account');
            setIsLoading(false);
        })
        .catch(err => {
            if(err.response && err.response.statusText === 'Bad Request') {
                errorMsg = "An error occured. Please check your verification link and try again.";
            } else {
                err.response.json().then(parsed => {
                    switch(err.response.status) {
                        case 400:
                            errorMsg = typeof parsed.message === 'object' ? Object.keys(parsed.message).map(k => `${parsed.message[k]} `) : parsed.message;
                            break;
                        default:
                            errorMsg = "An error occured. Please check your verification link and try again.";
                    }
                })
            }
            setHasError(true);
            setIsLoading(false);
        })
    }, []);

    if(!isLoading && !hasError) {
        return <Redirect to="/"/>
    }

    return (
        <IonPage>
            <IonContent>
                <p>{ errorMsg }</p>
            </IonContent>
        </IonPage>
    )
}

export default PageVerify