import React, { useEffect } from "react"
import VYPageWrapper from "../VYPageWrapper"
import { IonHeader, IonToolbar, IonTitle, IonContent, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react"
import SegmentCurrent from "./SegmentCurrent";
import SegmentArchives from "./SegmentArchives";
import SegmentStore from "./SegmentStore";

function PagePlans(props) {

	const pageSegments = {
		CURRENT: "_segment-current",
		ARCHIVES: "_segment-own",
		STORE: "_segment-vegly"
	}
	const [activeSegment, setSegment] = React.useState(pageSegments.CURRENT);
	
	useEffect(() => {
		window.analytics.page('Plans', activeSegment);
	}, [props.match, activeSegment])
	
	/**
	 * 
	 * @param {*} segment 
	 */
	const _toggleSegment = (e) => {
		setSegment(e.detail.value);
	}

    return(
		<VYPageWrapper>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Plans</IonTitle>
				</IonToolbar>
				<IonSegment mode="md" value={activeSegment} onIonChange={_toggleSegment}>
					<IonSegmentButton value={pageSegments.CURRENT}>
						<IonLabel>Current plan</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value={pageSegments.ARCHIVES}>
						<IonLabel>My plans</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value={pageSegments.STORE}>
						<IonLabel>Vegly plans</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</IonHeader>
			<IonContent>
				{activeSegment === pageSegments.CURRENT &&
					<SegmentCurrent/>
				}
				{activeSegment === pageSegments.ARCHIVES &&
					<SegmentArchives/>
				}
				{activeSegment === pageSegments.STORE &&
					<SegmentStore/>
				}
			</IonContent>
		</VYPageWrapper>
    )
}

export default PagePlans
