import React, { useState } from "react"
import { IonFab, IonFabButton, IonGrid } from "@ionic/react"
import SkeletonPlan from "../../components/SkeletonPlan"
import PlanDetail from "../../components/PlanDetail";
import PageEmptyPlaceholder from "../../components/PageEmptyPlaceholder";
import FloatingButtonText from "../../components/FloatingButtonText"
import { close, bookmarks } from "ionicons/icons";
import IconMaterialPencil from "./IconMaterialPencil"
import { useUserCurrentPlan } from "../../hooks";

function SegmentCurrent() {
    const { data, isLoading, isError } = useUserCurrentPlan();
    const [editEnabled, toggleEditMode] = useState(false);

    /**
     * 
     */
	const _toggleEditMode = () => {
        toggleEditMode(!editEnabled);
        window.analytics.track('Plan edit mode', { 'toggle': !editEnabled });
    }

    return(
        <>
            {isLoading &&
                <IonGrid fixed>
				    <SkeletonPlan/>
                </IonGrid>
			}
            {(!isLoading && data) && 
                <>
                    <IonGrid fixed>
                        <PlanDetail plan={data} groceryMode={!editEnabled} editMode={editEnabled}/>
                    </IonGrid>
                    {!editEnabled ? 
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            
                            <IonFabButton onClick={_toggleEditMode}>
                                <i>{IconMaterialPencil}</i>
                            </IonFabButton>
                        </IonFab>
                        :
                        <FloatingButtonText label="Exit edit mode" icon={close} color="dark" onClick={_toggleEditMode}/>
                    }
                </>
            }
            {(!isLoading && !data) &&
                <PageEmptyPlaceholder icon={bookmarks} message={
                    <>
                        <p>You don't have an active plan yet.</p>
                        <p>Head over to your plans and choose a plan to get started with.</p>
                    </>
                }/>
            }
        </>
    )
}

export default SegmentCurrent