import React from "react"
import { IonButton, IonIcon } from "@ionic/react"
import styles from "./FloatingButtonText.module.css"

function FloatingButtonText(props) {
    const { label, icon, color, onClick } = props;

    return(
        <div className={styles.container}>
            <span className={styles.sticky}>
                <IonButton shape="round" color={color || "primary"} onClick={onClick}>
                    {icon && 
                        <IonIcon slot="start" icon={icon} />
                    }
                    {label || "Label"}
                </IonButton>
            </span>
        </div>
    )
}

export default FloatingButtonText