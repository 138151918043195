import { useContext, useEffect, useState } from "react"
import FetchClient from "../utils/FetchClient"

import { AppContext } from "../AppContext"

const GQUERY = `
    query {
        currentPlan: UserGetCurrentPlan {
            id
            title
            menus {
                id
                assocId
                title
                thumb
                meal
                preptime
                difficulty
                rp_calories
                rp_fat
                rp_saturates
                rp_protein
                rp_carbohydrates
                rp_sugars
                rp_fibre
                rp_salt
                tags
            }
            nutrition {
                calories
                fat
                saturates
                protein
                carbohydrates
                sugars
                fibre
                salt
                group_id
            }
            updated_at
        }
    }
`;

export default (arg) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const { dispatch } = useContext(AppContext);

	useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: GQUERY
			}
		})
		.then((res) => {
			const plan = res.data.currentPlan;
            setData(plan);
            setIsLoading(false);
		})
		.catch((err) => {
            err.response.json().then(parsed => dispatch({
                type: 'setError',
                error: parsed
            }));
            setIsError(err);
            setIsLoading(false);
		});
	}, [arg])

    return { data, isLoading, isError};
}