import React from "react"
import { useHistory } from "react-router-dom"
import { IonContent, IonPage, IonButton } from "@ionic/react"
import styles from "./PageCheckoutSuccess.module.css"

const checkSvg = <svg xmlns="http://www.w3.org/2000/svg" width="100.486" height="100" viewBox="0 0 100.486 100"><g transform="translate(-130 -84)"><circle cx="46.5" cy="46.5" r="46.5" transform="translate(137 91)" fill="#d2f7e0"/><g transform="translate(130 84)" fill="none" stroke="#214b31" stroke-width="2"><circle cx="47.5" cy="47.5" r="47.5" stroke="none"/><circle cx="47.5" cy="47.5" r="46.5" fill="none"/></g><path d="M156.693,123.637l27.389,28.033L235.4,92.206" transform="translate(-5.671 -3.217)" fill="none" stroke="#214b31" stroke-width="2"/></g></svg>;

function PageCheckoutSuccess() {  
    const history = useHistory();

    const _goToHome = () => {
        history.push('/tabs/home', { 'direction': 'forward' });
    }

    return(
        <IonPage>
            <IonContent>
                <div className={styles.wrapper}>
                    <span>
                        {checkSvg}
                    </span>
                    <h1>Welcome!</h1>
                    <div className={styles.message}>
                        <p>Thank you for purchasing a Vegly Pass, you will now be able to acces all features.</p>
                        <p>Click the button below to go to your home tab. Happy Vegly!</p>
                    </div>
                    <IonButton onClick={_goToHome}>Go to the home tab</IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default PageCheckoutSuccess
