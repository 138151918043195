import React, { useContext, useEffect, useState } from "react"
import { IonPage, IonCheckbox, IonContent, IonItem, IonInput, IonButton, IonLabel, IonSpinner, IonToast, IonGrid, IonRow, IonCol } from "@ionic/react"
import { Link, Redirect, useHistory } from "react-router-dom"
import FetchClient from "../utils/FetchClient"
import Auth from "../utils/Auth"
import { AppContext } from "../AppContext"
import styles from "./PageSignin.module.css";

var errorMsg;

function PageSignin(props) {
    const [isSignup, setIsSignup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [input, setInput] = useState({})
    const [checkedMarketing, setCheckedMarketing] = useState(false);
    const history = useHistory();
    const { dispatch } = useContext(AppContext);

    useEffect(() => {
        if(props.match.path.indexOf("signup") > -1) {
            window.analytics.page('Signup', { 'surface': 'app' });
            setIsSignup(true);
        } else {
            window.analytics.page('Signin', { 'surface': 'app' });
        }
    }, []);

    const _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = value;
        setInput(Object.assign({}, input, object));
    }

    const _signIn = (e) => {
        e.preventDefault();
        setIsLoading(true);

        var data =  {
            'firstname': input.firstname,
            'email': input.uname,
            'password': input.upwd
        };

        if(isSignup) {
            data['mktg'] = checkedMarketing;
        }

        FetchClient({
            method: 'post',
            data: data,
            url: `${process.env.REACT_APP_REST_HOST}/auth${isSignup ? '/register' : ''}`
        }).then(res => {
            if(res['authToken']) {
                Auth.signin(res['authToken']);
            }
            return res;
        })
        .then(res => {
            setTimeout(() => {
                // if the user is authenticated successfully...
                if(Auth.isAuthenticated()) {
                    dispatch({
                        type: 'setUser',
                        user: res
                    });

                    window.analytics.identify(`vuid-${res.id}`, {
                        name: `User ${res.id}`,
                        subscription: res.role
                    });

                    if(isSignup) {
                        window.analytics.track('Sign-up', { 'surface': 'app' }, () => {
                            history.replace('/getstarted', { 'direction': 'forward'});
                        });
                    } else {
                        window.analytics.track('Sign-in', { 'surface': 'app' }, () => {
                            history.replace('/tabs/home', { 'direction': 'forward'});
                        });
                    }
                }
                // cleanup
                setIsLoading(false);
            }, 1500)
        })
        .catch(err => {
            if(!err.response)
                throw new Error('Server error');

            err.response.json().then(parsed => {
                setIsLoading(false);
                switch(err.response.status) {
                    case 400:
                        errorMsg = "An error occured. Please try again.";
                        break;
                    case 401:
                        errorMsg = "Wrong email or password. Please check your credentials and try again.";
                        break;
                    case 404:
                        errorMsg = "This user doesn't exist."
                        break;
                    case 500:
                        errorMsg = typeof parsed.message === 'object' ? Object.keys(parsed.message).map(k => `${parsed.message[k]} `) : parsed.message;
                        break;
                    default:
                        errorMsg = "An error occured. Please check the details provided and try again.";
                }
                setHasError(true);
            })
        })
    }

    if(Auth.isAuthenticated()) {
        return <Redirect to="/tabs/home"/>;
    }

    return(
        <IonPage>
            <IonContent className={styles.contentWrapper}>
                <header>
                    {/*<div className={styles.logo}><span>&nbsp;</span></div>*/}
                    <h1>{isSignup ? "Create your free account" : "Sign in"}</h1>
                    {!isSignup ?
                        <p>New to Vegly? <Link className={styles.headerCta} to="/signup">Sign up for free</Link></p>
                        :
                        <p>Already have a Vegly account? <Link className={styles.headerCta} to="/">Sign in</Link></p>
                    }
                </header>
                <IonGrid fixed>
                    <IonRow>
                        <IonCol sizeMd="6" offsetMd="3">
                            <form onSubmit={_signIn}>
                                {isSignup && 
                                    <IonItem lines="full" className={styles.formInputGroup}>
                                        <IonInput name="firstname" type="text" placeholder="Name" required value={input['firstname'] || ''} onIonChange={_onChange}/>
                                    </IonItem>
                                }
                                <IonItem lines="full" className={styles.formInputGroup}>
                                    {/*<IonLabel position="floating">Your email</IonLabel>*/}
                                    <IonInput name="uname" type="email" placeholder="Email address" required value={input['uname'] || ''} onIonChange={_onChange}/>
                                </IonItem>
                                <IonItem lines="full" className={styles.formInputGroup}>
                                    {/*<IonLabel position="floating">Your password</IonLabel>*/}
                                    <IonInput name="upwd" type="password" placeholder="Password" required value={input['upwd'] || ''} onIonChange={_onChange}/>
                                </IonItem>
                                {!isSignup &&
                                    <Link className={styles.resetLink} to="/reset">Forgot your password?</Link>
                                } 
                                {isSignup && 
                                    <div className={styles.terms}>
                                        <IonItem lines="none" className={styles.formGroup}>
                                            <IonCheckbox checked={checkedMarketing} onIonChange={e => setCheckedMarketing(e.detail.checked)}/>
                                            <IonLabel className={[styles.label, styles.checkbox].join(' ')}>I would like to receive the latest FREE tips and guides from Vegly. I can unsubscribe at any time.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none" className={styles.formGroup}>
                                            <IonLabel className={[styles.label, styles.center].join(' ')}>By creating an account, you agree to the <Link to="//www.eatvegly.com/terms/" target="_blank">Terms of Service</Link> and acknowledge our <Link to="//www.eatvegly.com/privacy/" target="_blank">Privacy Policy</Link></IonLabel>
                                        </IonItem>
                                    </div>
                                }
                                <div className={styles.formActions}>
                                    {isLoading ? 
                                        <span className={styles.spinnerContainer}>
                                            <IonSpinner name="crescent"/>
                                        </span>
                                        :
                                        <IonButton type="submit" expand="block">{isSignup ? "Create an account" : "Sign in"}</IonButton>
                                    }
                                </div>
                            </form>
                            <IonToast
                                isOpen={hasError}
                                onDidDismiss={() => setHasError(false)}
                                message={errorMsg}
                                position="bottom"
                                duration={5000}
                                color="warning"
                                className={styles.warningToast}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default PageSignin