import { useContext, useEffect, useState } from "react"
import FetchClient from "../utils/FetchClient"

import { AppContext } from "../AppContext"

const GQUERY = `
    query {
        userPlans: UserListPlans {
            results {
                id
                title
                rendered_title
                is_active
                updated_at
            }
        }
        currentPlan: UserGetCurrentPlan {
            id
        }
    }
`;

export default (arg) => {
    const [userPlans, setUserPlans] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const { dispatch } = useContext(AppContext);

	useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: GQUERY,
			}
		})
		.then((res) => {
			setUserPlans(res.data.userPlans.results);
			setCurrentPlan(res.data.currentPlan);
            setIsLoading(false);
		})
		.catch((err) => {
            err.response.json().then(parsed => dispatch({
                type: 'setError',
                error: parsed
            }));
            setIsError(err);
            setIsLoading(false);
		});
	}, [arg])

    return { userPlans, currentPlan, isLoading, isError};
}