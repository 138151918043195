import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { IonAlert, IonPage, IonHeader, IonToolbar, IonTitle, IonCheckbox, IonContent, IonItem, IonLabel, IonButton, IonInput, IonIcon, IonGrid, IonRow, IonCol, IonToast } from "@ionic/react"
import FetchClient from "../../utils/FetchClient"
import { lockClosedOutline, openOutline, createOutline, bulbOutline, alertCircle } from "ionicons/icons"
import styles from "./PageAccount.module.css"
import Auth from "../../utils/Auth"
import MembershipBanner from "../../components/MembershipBanner"
import FeedbackWidget from "../../components/FeedbackWidget"
import moment from "moment"

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const GET_USER_ACCOUNT = `
    query {
        userProfile: UserGetMe {
			id
			email
			firstname
			verified
			isPremium
			activeSub
			pendingCancellation
        }
    }
`;

const GET_USER_SETTINGS = `
	query {
		userSettings: UserGetSettings {
			mktg_consent
		}
	}
`;

const GQ_DOWNGRADE = `
	mutation {
		SubscriptionsDowngrade {
			cancel_at
			cancel_at_period_end
		}
	}
`;

const GQ_SAVE_SETTNGS = `
	mutation($settings: [UserSettingInput]) {
		UserEditSettings(settings: $settings)
	}
`;

var toastMsg = '';

function PageAccount(props) {
	const [user, setUser] = useState(null);
	const [settings, setSettings] = useState(null);
	const [showDowngradeAlert, setShowDowngradeAlert] = useState(null);
	const [showToast, setShowToast] = useState(null);
	const [toggleFeedbackWidget, setToggleFeedbackWidget] = useState(null);
	const history = useHistory();

	useEffect(() => {
		window.analytics.page('Account');
	}, [props.match])

	useEffect(() => {
		FetchClient({
			method: 'post',
			data: {
				query: GET_USER_ACCOUNT,
			}
		})
		.then((res) => {
			setUser(res.data.userProfile);
		})
		.catch((err) => {
			console.error(err);
		});

		FetchClient({
			method: 'post',
			data: { query: GET_USER_SETTINGS }
		})
		.then((res) => {
			setSettings(res.data.userSettings);
		})
		.catch((err) => {
			console.error(err);
		});
	}, [])

	const _onDowngrade = () => {
		FetchClient({
			method: 'post',
			data: {
				query: GQ_DOWNGRADE,
			}
		})
		.then((res) => {
			if(res.data.SubscriptionsDowngrade.cancel_at_period_end) {
				setUser(Object.assign({}, user, { 'pendingCancellation': res.data.SubscriptionsDowngrade.cancel_at }))
			} 
			else throw new Error("Something went wrong, try again or contact support"); 
		})
		.catch((err) => {
			console.error(err);
		});
	}

	const _onSavePreferences = (e) => {
		const { checked } = e.detail;
		const settings = [{'setting_key': 'mktg_consent', 'setting_value': checked ? "1" : "0"}];
		setSettings({ mktg_consent: checked ? "1" : "0" });

		FetchClient({
			method: 'post',
			data: {
				query: GQ_SAVE_SETTNGS,
				variables: { 'settings': settings }
			}
		})
		.then((res) => {
			toastMsg = "Preferences saved";
			setShowToast(true)
		})
		.catch((err) => {
			console.error(err);
		});
	}
	
	const _onSubmitFeedback = (message) => {
		console.log(message)
	}

	const _onVerifyEmail = () => {
        FetchClient({
            method: 'put',
            data: { 'email': user.email },
            url: `${process.env.REACT_APP_REST_HOST}/auth/verify`
        })
        .then(res => {
			toastMsg = "We sent a verification link to your email. Click the link to verify your address";
			setShowToast(true);
        })
        .catch(err => {
            if(err.response && err.response.statusText === 'Bad Request') {
                toastMsg = "An error occured. Please check your verification link and try again.";
            } else {
                err.response.json().then(parsed => {
                    switch(err.response.status) {
                        case 400:
                            toastMsg = typeof parsed.message === 'object' ? Object.keys(parsed.message).map(k => `${parsed.message[k]} `) : parsed.message;
                            break;
                        default:
                            toastMsg = "An error occured. Please check your verification link and try again.";
                    }
                })
            }
            setShowToast(true);
		})
	}

	const _gotoEditPassword = () => {
		history.push('/account/edit_password', { 'direction': 'forward' });
	}

	const _onLogout = () => {
		Storage.clear()
		Auth.signout();
		window.analytics.page('Signed out');
		setTimeout(() => history.push('/signin', { direction: 'forward' }), 1000);
	}

    return(
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Account</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol>
					{!user && 
						<p>Loading...</p>
					}
					{user &&
						<>
							<div className={styles.section}>
								<h6 className={styles.title}>Membership</h6>
								<div className={styles.content}>
									<p>{user.activeSub}</p>
									{user.pendingCancellation &&
										<p className="caption">You recently cancelled your pass. Your membership will be downgraded back to 'Basic' on {moment.unix(user.pendingCancellation).format("MMM Do, YYYY")}</p>
									}
									{!user.isPremium &&
										<MembershipBanner/>
									}
									{(user.isPremium && !user.pendingCancellation && user.activeSub !== 'Lifetime') &&
										<IonButton color="light" onClick={() => setShowDowngradeAlert(true)}>Cancel your pass</IonButton>
									}
								</div>
							</div>
							<div className={styles.section}>
								<h6 className={styles.title}>Personal information</h6>
								<IonItem>
									<IonLabel position="stacked">Name</IonLabel>
									<IonInput value={user.firstname}></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position="stacked">Email</IonLabel>
									<IonInput value={user.email} readonly></IonInput>
									{ !user.verified ?
										<IonButton fill="clear" className={styles.inputFloatingButton} onClick={_onVerifyEmail}>
											<IonIcon icon={alertCircle} slot="start"/>
											Verify email
										</IonButton>
									:
										<IonButton fill="clear" disabled={true} className={styles.inputFloatingButton}>
											<IonIcon icon={lockClosedOutline} slot="start"/>
											Verified
										</IonButton>
									}
								</IonItem>
								<IonItem>
									<IonLabel position="stacked">Password</IonLabel>
									<IonInput value="passw" type="password" readonly></IonInput>
									<IonButton fill="clear" className={styles.inputFloatingButton} onClick={_gotoEditPassword}>
										<IonIcon icon={createOutline} slot="icon-only"/>
									</IonButton>
								</IonItem>
							</div>
							<div className={styles.section}>
								<h6 className={styles.title}>Marketing preferences</h6>
								<IonItem className={styles.checkbox}>
                                    <IonCheckbox checked={settings && settings.mktg_consent === "1"} onIonChange={_onSavePreferences}/>
                                    <IonLabel className={styles.label}>Receive in your inbox the latest FREE tips and updates from Vegly</IonLabel>
                                </IonItem>
							</div>
						</>
					}
					{/* 
					<div className={[styles.section, styles.sectionContact].join(' ')}>
						<h6 className={styles.title}>Get in touch</h6>
						<IonItem lines="full" onClick={() => setToggleFeedbackWidget(!toggleFeedbackWidget)}>
							<IonIcon icon={bulbOutline}/>
							<IonLabel>Help improve Vegly</IonLabel>
						</IonItem>
					</div>
					*/}
					<div className={styles.section}>
						<h6 className={styles.title}>About Vegly</h6>
						<IonItem lines="full" href="//www.eatvegly.com/faqs/" target="_blank">
							<IonLabel>FAQs</IonLabel>
							<IonIcon icon={openOutline} size={"small"}/>
						</IonItem>
						<IonItem lines="full" href="//www.eatvegly.com/terms/" target="_blank">
							<IonLabel>Terms and Conditions</IonLabel>
							<IonIcon icon={openOutline} size={"small"}/>
						</IonItem>
						<IonItem lines="full" href="//www.eatvegly.com/privacy/" target="_blank">
							<IonLabel>Privacy Policy</IonLabel>
							<IonIcon icon={openOutline} size={"small"}/>
						</IonItem>
					</div>
					<footer className={styles.section}>
						<IonButton color="dark" expand="block" onClick={_onLogout}>Logout</IonButton>
					</footer>
					</IonCol>
					</IonRow>
				</IonGrid>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toastMsg}
					position="bottom"
					duration={5000}
					color="dark"
					//className={styles.warningToast}
				/>
		        <IonAlert
					isOpen={showDowngradeAlert}
					onDidDismiss={() => setShowDowngradeAlert(false)}
					header={'Sorry to see you go'}
					message={'Your account will be downgraded to basic membership at the end of the billing period. You will no longer have access to premium features, including any customised meal plans and only have access to your original free plan.'}
					buttons={[
						{
							text: 'Stay and keep the Pass'
						},{
							text: 'Confirm and cancel the Pass',
							handler: () => _onDowngrade()
						}
					]}
					backdropDismiss={false}
				/>
				<FeedbackWidget
					show={toggleFeedbackWidget}
					onClose={() => setToggleFeedbackWidget(false)}
					onSubmit={_onSubmitFeedback}
				/>
			</IonContent>
		</IonPage>
    )
}

export default PageAccount