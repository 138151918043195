import React from "react"
import styles from "./CaloriesDonut.module.css"

const icon = 
	<svg width="24px" height="33px" viewBox="0 0 24 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="calories_icon" transform="translate(-8.000000, -4.000000)">
				<rect id="bounding" fillOpacity="0" fill="#cea668" x="0" y="0" width="40" height="40"></rect>
				<path d="M28,25.7121419 C28,24.7464909 27.6439762,23.4012915 26.9206492,21.70737 C26.7584428,22.0087217 26.5922432,22.3145312 26.4220493,22.6248031 L24.8851285,25.4266846 L23.4671641,22.5627639 C21.0773418,17.7359423 19.4718564,13.9495743 18.6490315,11.1232191 C13.7726386,18.1673441 11.3333333,23.1142465 11.3333333,25.7121419 C11.3333333,27.8924392 12.3044227,29.9217616 13.9453717,31.3675945 C14.686329,29.8597384 15.8673616,28.0292185 17.4936496,25.8317767 L18.8333333,24.0215948 L20.173017,25.8317767 C22.135962,28.4841097 23.455183,30.5974944 24.1319069,32.281278 C26.4967947,30.8792903 28,28.4216153 28,25.7121419 Z M25.4726828,17.2623307 L26.9032636,14.3871609 L28.4308747,17.2119737 C30.3526912,20.7657392 31.3333333,23.5631738 31.3333333,25.7121419 C31.3333333,30.4825817 28.1546901,34.6555732 23.5276773,36.2001078 L21.3219156,36.9364091 L21.3333088,34.6110286 L21.3333333,34.6010308 C21.3333333,33.8444594 20.4990582,32.1410532 18.8328024,29.6953166 C17.4058464,31.7876556 16.5834643,33.3471493 16.374898,34.2948341 L15.9019154,36.4439756 L13.961324,35.4063694 C10.3172096,33.4579143 8,29.7842955 8,25.7121419 C8,21.7456463 11.4393429,15.2491098 18.3214035,5.83936039 L19.6666667,4 L21.0119299,5.83936039 L21.0336406,5.86905126 L21.3953889,6.36386824 L21.350345,6.97515928 C21.3390532,7.12840007 21.3333333,7.28624347 21.3333333,7.44860905 C21.3333333,9.23622113 22.567788,12.8563546 25.0330409,18.1335417 C25.1843397,17.8377155 25.3308875,17.5473097 25.4726828,17.2623307 Z" id="Combined-Shape" fill="#cea668"></path>
			</g>
		</g>
	</svg>;

function CaloriesDonut({ count = null, progress = 50, subline = null }) {
	// progress value in percentage multiplied by the pixel circumference of the circle
	// all divided by 100
	const calcProgress = progress < 100 ? 615 - ((progress*615)/100) : 0 // 0 is full circle; 

    return(
		<div className={styles.statsChartContainer}>
			<div className={styles.title}>
				<span className={styles.icon}>{icon}</span>
				<p className={styles.counter}>{count}</p>
				<p className={styles.label}>calories{subline && <span>{subline}</span>}</p>
			</div>
			<svg className={styles.statsDonut} height="220" width="220">
				<g>
					<title>Stroke Layer</title>
					<circle id="track" strokeOpacity="0.1" stroke="#235536" strokeWidth="2" r="98" cy="110" cx="110" fill="none"></circle>
					<circle id="progress" className={styles.circle_animation} r="98" cy="110" cx="110" strokeWidth="12" stroke="#000" fill="none" style={{strokeDashoffset: calcProgress}}></circle>
				</g>
			</svg>
		</div>
    )
}

export default CaloriesDonut