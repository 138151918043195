import React from "react"
import CalorieDonut from "../../components/CaloriesDonut"
import styles from "./WidgetCalories.module.css"

export default ({ calories = 0}) => {
    const progress = calories < 2000 ? (calories*100)/2000 : 100;

    return(
        <div className={styles.widgetCalories}>
            <CalorieDonut count={Math.round(calories)} progress={progress} subline={"eaten today"}/>
        </div>
    )
}