import React from "react"
import { IonAlert } from "@ionic/react"
import { useHistory } from "react-router"

function UpsellAlert(props) {
    const { show, onCancel } = props;
    const history = useHistory();

    return(
        <IonAlert
            isOpen={show}
            onDidDismiss={() => onCancel(false)}
            header={'Get a Vegly Pass'}
            message={'This is a paid feature. Buy a Vegly Pass to upgrade your membership and access unlimited features'}
            buttons={[
                {
                    text: 'Maybe later'
                },{
                    text: 'View passes',
                    handler: () => history.push('/subscription')
                }
            ]}
            backdropDismiss={false}
        />
    )
} 

export default UpsellAlert