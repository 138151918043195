import React from "react"
import { IonPage } from "@ionic/react"

// Custom page wrapper to add some bottom margin to every page
// and avoid content is hidden behind the custom tabbar when scrolling

function VYPageWrapper({ children }) {
    return <IonPage>{children}</IonPage>
}

export default VYPageWrapper