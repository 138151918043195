import React, { useEffect } from "react"
import { IonButtons, IonPage, IonHeader, IonToolbar, IonContent, IonItem, IonLabel, IonButton, IonIcon, IonList, IonNote } from "@ionic/react"
import VYBackButton from "../../components/VYBackButton"
import FetchClient from "../../utils/FetchClient"
import styles from "./PageSubs.module.css"
import { checkmarkCircle, addOutline, gift } from "ionicons/icons"

const stripe = window.Stripe ? window.Stripe(process.env.REACT_APP_STRIPE_PK) : null

const CREATE_CHECKOUT = `
    query($plan: String!) {
        StartCheckout(plan: $plan)
    }
`;

function ProductItem({id, content, onSelect, isSelected}) {
    return(
        <button className={[styles.productSelection, isSelected ? styles.selected : ''].join(' ')} onClick={() => onSelect(id)}>
            {isSelected && <IonIcon icon={checkmarkCircle}/>}
            {content}
        </button>
    )
}

function SaleBanner({ discount }) {
    return(
        <div className={styles.saleBanner}>
            <IonItem lines="none">
                <IonIcon icon={gift}></IonIcon>
                <IonLabel>Autumn special, { discount } off every pass</IonLabel>
            </IonItem>
        </div>
    )
}

function PageSubs(props) {
    const [selected, setSelection] = React.useState(null);
    
	useEffect(() => {
		window.analytics.page('Subscriptions');
	}, [props.match]);
    
    const _onSelect = (subscriptionId) => {
        setSelection(subscriptionId)
    }

	const _checkout = () => {
		FetchClient({
			method: 'post',
			data: {
                query: CREATE_CHECKOUT,
                variables: {
                    plan: selected
                }
			}
		})
		.then((res) => {
            stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: res.data.StartCheckout.id
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
		})
		.catch((err) => {
			console.error(err);
        });
    }

    return(
		<IonPage>
			<IonHeader>
				<IonToolbar>
                    <IonButtons slot="start">
                        <VYBackButton/>
                    </IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className={styles.contentWrapper}>
                <h1>Supercharge your vegetarian lifestyle</h1>
                <IonList className={styles.benefits}>
                    <IonItem lines="full">
                        <IonIcon icon={addOutline}></IonIcon>
                        <IonLabel>Access to all our weekly, balanced, seasonal meal plans</IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonIcon icon={addOutline}></IonIcon>
                        <IonLabel>Access to all 700+ recipes including over 500 vegan and vegan friendly recipes</IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonIcon icon={addOutline}></IonIcon>
                        <IonLabel>Create a library of all your meal plans to use and customise as you like</IonLabel>
                    </IonItem>
                </IonList>
                <div className={styles.passSelector}>
                    <h6>Select your pass and checkout:</h6>
                    <ProductItem 
                        id="monthly"
                        onSelect={_onSelect}
                        content={
                            <>
                                <p>£5.99/month</p>
                                <p className={styles.subline}>Monthly pass {/*<span className={styles.strikeText}>£5.99/month</span>*/}</p>
                            </>
                        }
                        isSelected={selected === "monthly"}
                    />
                    <ProductItem 
                        id="yearly"
                        onSelect={_onSelect}
                        content={
                            <>
                                <p>£49.99/year</p>
                                <p className={styles.subline}>Yearly pass {/*<span className={styles.strikeText}>£49.99/year</span>*/}</p>
                            </>
                        }
                        isSelected={selected === "yearly"}
                    />
                </div>
                <div className={styles.otherBenefits}>
                    <h6>Already included in your free membership:</h6>
                    <IonList>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>One week plan of balanced meals, forever free</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>Full day plans for the entire week</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>Nutrition info for every meal</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>Step by step recipes</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>Easy grocery list</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonIcon icon={addOutline}></IonIcon>
                            <IonLabel>Customise your plan with selected free recipes</IonLabel>
                        </IonItem>
                    </IonList>
                </div>
                <footer>
                    <IonNote className={styles.disclaimer}>Recurring billing. Cancel anytime</IonNote>
                    <IonNote className={styles.disclaimer}><a href="/" target="_blank">Terms of Service and Privacy Policy</a></IonNote>
                </footer>
			</IonContent>
            <div className={styles.stickyCheckout}>
                {/*<SaleBanner discount="35%"/>*/}
                <IonButton expand="block" disabled={!selected} onClick={_checkout}>Checkout</IonButton>
            </div>
		</IonPage>
    )
}

export default PageSubs