import React, { useEffect, useRef, useState } from "react"
import { IonContent } from "@ionic/react"
import styles from "./BackdropContent.module.css"

export default function BackdropContent(props) {
    const { title, description, backLayerContent, frontLayerContent, isExpanded } = props;

    const frontLayerRef = useRef(null);
    const topPositioning = isExpanded ? (props.expandedSize || "50%") : "0";
    const [scrollableHeight, setScrollableHeight] = useState(`calc(100% - ${topPositioning})`);

    useEffect(() => {
        var posTop = frontLayerRef.current.offsetTop;
        // to avoid a visual cut of the front layer panel
        // adjust the height to 100% when the panel starts closing
        frontLayerRef.current.addEventListener('transitionstart', () => {
            if(!isExpanded && posTop > 0)
                setScrollableHeight("100%");
        }, 
        {'once': true});

        // to avoid a visual cut of the front layer panel
        // recalculate the height when the panel starts opening
        // - this is necessary so that scrilling the entire content 
        // of the panel is still possible when expanded
        frontLayerRef.current.addEventListener('transitionend', () => {
            if(isExpanded)
                setScrollableHeight(`calc(100% - ${topPositioning})`)
        }, 
        {'once': true})
    }, [frontLayerRef, topPositioning])

    return(
        <IonContent className={styles.backdropContent} padding scrollY={false}>
            <div className={styles.backdropBackLayer}>
                {backLayerContent}
            </div>

            <div ref={frontLayerRef} className={styles.backdropFrontLayer} style={{top: topPositioning, height: scrollableHeight}}>
                <header>
                    <h2 className={styles.title}>{title}</h2>
                    { description && 
                        <p className={styles.description}>{description}</p>
                    }
                </header>
                <div className={styles.content}>
                    {frontLayerContent}
                </div>
            </div>
        </IonContent>
    )
}